declare global {
    interface String {
        // padZero(length : number) : string;
        addToken(token: string | undefined | null, div: string): string
    }
}

String.prototype.addToken = function (token: string | undefined | null, div: string): string {
    if (token && this)
        return this + div + token
    else if (token)
        return token
    else
        return this.toString()
}

type FieldString = string | undefined

export function searchInFields(s: string, ...args: FieldString[]): boolean {
    const s0 = s.toLowerCase()
    for (let index = 0; index < args.length; index++) {
        const arg = args[index]
        if (arg && arg.toLowerCase().indexOf(s0) >= 0)
            return true
    }
    return false
}

export function beforToken(s: string, token: string, def?: string): string | undefined {
    const n = s.indexOf(token);
    if (n < 0) {
        return def;
    }
    return s.substring(0, n);
}

export function afterToken(s: string, token: string, def?: string): string | undefined {
    const n = s.indexOf(token);
    if (n < 0) {
        return def;
    }
    return s.substring(n + token.length);
}

export { }