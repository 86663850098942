import { SubmitHandler, useForm } from "react-hook-form"
import { emailValidation, passwordValidation, titleValidation } from "../../comp/forms/validations"
import strings from "../../strings"
import { LabelWith } from "../../comp/Label"
import AuthService from "../../services/AuthService"

export interface IRegisterForm {
    fullname: string
    username: string
    password: string
    registrationCode: string | undefined
}

interface IRegisterFormTabProps {
    title: string
    onSubmit: SubmitHandler<IRegisterForm>
}

export function RegisterTab({ title, onSubmit }: IRegisterFormTabProps) {
    const { register, formState: { errors }, handleSubmit } = useForm<IRegisterForm>({ defaultValues: {} as IRegisterForm })
    return (
        <form title={title} className='flex flex-col space-y-2 mt-2'
            onSubmit={handleSubmit(onSubmit)}>
            <LabelWith text={strings.lab_fullname} error={errors.fullname}>
                <input className='textinput' 
                    placeholder={strings.lab_fullname}
                    {...register("fullname", titleValidation())} />
            </LabelWith>

            <LabelWith text={strings.lab_email} error={errors.username}>
                <input className='textinput' 
                    placeholder={strings.lab_email}
                    {...register("username", emailValidation(true))} />
            </LabelWith>

            <LabelWith text={strings.lab_password} error={errors.password}>
                <input className='textinput' type="password"
                    placeholder={strings.lab_password}
                    {...register("password", passwordValidation())} />
            </LabelWith>

            {AuthService.registrationCode && 
            <LabelWith text={strings.lab_registration_code} error={errors.registrationCode} 
                requiredMessage={strings.msg_registration_limited}>
                <input className='textinput' type="password"
                    placeholder={strings.lab_registration_code}
                    {...register("registrationCode", {required: true})} />
            </LabelWith>
            }

            <button type='submit' 
                className='textbutton'>{strings.button_register}</button>
        </form>
    )
}