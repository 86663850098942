import { useNavigate, useParams } from "react-router-dom"
import { NominationsSortDir, useContest, useNominations } from "../hooks/useItems"
import strings from "../strings"
import { Loading } from "../comp/Loading"
import { TextField } from "../comp/TextField"
import { useTypedSelector } from "../hooks/useTypedSelector"
import NominationRow from "../comp/rows/NominationRow"
import { LabelWith } from "../comp/Label"
import DataService from "../services/DataService"
import { ListUseItems } from "../comp/ListItems"
import { ImageView } from "../comp/AvatarView"
import { PhotoSize } from "../model/models"
import { ErrorMessage } from "../comp/ErrorMessage"
import paths from "../paths"
import { useTitle } from "../hooks/useTitle"
import { DescrView } from "../comp/DescrView"

export function ParticipatePage() {
    const navigate = useNavigate()
    const contestId = useParams().contestId || ""
    const { isAuth } = useTypedSelector(state => state.auth);

    const { contest, loading } = useContest(contestId)
    const nominations = useNominations(contestId, NominationsSortDir.INDEX_ASC)
    useTitle(strings.lab_contest + (contest ? " - " + contest.title : ""))

    async function participateInClickHandler() {
        // FIXME
        // if (isAuth) {
        //     try {
        //         const resp = await DataService.participateContest(contestId, false)
        //         if (resp.statusText === "OK")
        //             navigate(paths.contest_by_id(contestId))
        //     } catch(e) {
        //         console.error(e)
        //     }
        // } else {
        //     navigate(`${paths.login}?redirect=${global.location.pathname}`)
        // }        
    }

    return (
        <div className="maincontainer w-full flex flex-col gap-y-2">
            <span className="debugfield">#{contestId}</span>
            {contest ?
                <>
                    <div className="flex flex-row gap-x-4">
                        <ImageView src={contest.logo} size={PhotoSize.large} className="w-1/2" />
                        <div className="flex-col w-1/2">
                            <TextField label={strings.lab_title} text={contest.title} />
                            <LabelWith text={strings.lab_descr}>
                                <DescrView body={contest.description} className="textfield" />
                            </LabelWith>
                            <LabelWith text={strings.lab_user_status}>
                                {contest.isAdmin
                                    ? <span>{strings.lab_user_status_admin}</span>
                                    : contest.isParticipating
                                        ? <span>{strings.lab_user_status_contestant}</span>
                                        : <span>{strings.lab_user_status_none}</span>
                                }
                            </LabelWith>
                        </div>
                    </div>

                    <div className="mt-8 flex flex-row justify-center gap-x-2">
                        <button className="textbutton" onClick={() => navigate(paths.presentation_by_id(contestId))}>{strings.nav_presentation}</button>
                        {contest.isAdmin || contest.isParticipating
                            ? <button className="greenbutton" onClick={() => navigate(paths.contest_by_id(contestId))}>{strings.button_open_contest}</button>
                            : <button className="greenbutton" onClick={participateInClickHandler}>{strings.button_participate_in}</button>
                        }
                    </div>

                    <LabelWith text={strings.lab_nominations}>
                        <ListUseItems useItems={nominations}
                            render={item =>
                                <NominationRow item={item} key={item.id || item.title} />
                            }
                        />
                    </LabelWith>
                </>
                : loading
                    ? <Loading />
                    : <ErrorMessage message={strings.err_load_contest} />
            }
        </div>
    )
}