import { useState } from "react"
import { ListItems } from "../../comp/ListItems"
import { Modal } from "../../comp/Modal"
import { CreateContestEntity } from "../../comp/forms/CreateContestEntity"
import ContestEntityRow from "../../comp/rows/ContestEntityRow"
import { searchInFields } from "../../extends/string"
import { EntitySortDir, useContestEntities, useNominationsAll } from "../../hooks/useItems"
import { useModal } from "../../hooks/useModal"
import { dbid, EntityState, IContest, IContestEntity, ITemplateField, MAX_NOMINATION_COUNT, TemplateFieldType } from "../../model/models"
import strings from "../../strings"
import { LabelWith } from "../../comp/Label"
import { fetchFromObject } from "../../model/query"
import { TextInput, TextInputType } from "../../comp/TextInput"
import { ComboKeyText, Combobox2 } from "../../comp/Combobox"
import { ITagItem, TagsView } from "../../comp/TagsView"
import { useGlobalState } from "../../hooks/useGlobalState"
import { IconButton } from "../../comp/Icon"
import { Loading } from "../../comp/Loading"
import { BottomLogoView } from "../../comp/LogoView"

function checkFilter(obj: any, filter: ITagItem) {
    let value = fetchFromObject(obj, filter.name)
    // console.log(`checkFilter filter ${filter.name}="${filter.value} -- ${value}"`)
    if (!value && !filter.value) {
        return true
    } else if (value === filter.value) {
        return true
    } else if (Array.isArray(value) && Array.isArray(filter.value)) {
        // console.log("Compare arrays", value, filter.value, value == filter.value)
        return JSON.stringify(value) === JSON.stringify(filter.value)
    } else if (Array.isArray(value)) {
        return value.indexOf(filter.value) >= 0
    } else
        return false
}

function checkFilters(obj: any, filters: ITagItem[]) {
    if (filters.length === 0)
        return true
    for (let filter of filters) {
        if (!checkFilter(obj, filter))
            return false
    }
    return true
}

function buildFilters(contest: IContest) {
    let res = contest.template?.fields?.flatMap(fld => {
        let name = `fields.${fld.name}`
        if (fld.type === TemplateFieldType.BOOL) {
            return [
                { name, value: "true", text: `${fld.label}: Да`, group: name } as ITagItem,
                { name, value: "false", text: `${fld.label}: Нет`, group: name } as ITagItem
            ]
        } else if (fld.type === TemplateFieldType.ENUM) {
            if (!fld.enums)
                return []

            let res = fld.enums.map(s => {
                return { name, value: s, text: `${fld.label}: ${s}`, group: name } as ITagItem
            })
            if (res.length > 0)
                res.push({ name, value: "", text: `${fld.label}: нет`, group: name } as ITagItem)
            return res
        } else {
            return [{ name, value: "", text: `${fld.label}: нет`, group: name } as ITagItem]
        }
    }) || []
    res.push({ name: "nominationIds", value: [], text: "Без номинаций", group: "nominationIds" } as ITagItem)
    res.push({ name: "state", value: EntityState.CORRECTION_REQUIRED, text: "Требуют коррекции", group: "state" } as ITagItem)
    res.push({ name: "state", value: EntityState.REJECTED, text: "Отказано", group: "state" } as ITagItem)
    res.push({ name: "state", value: EntityState.APPROVED, text: "Одобрены", group: "state" } as ITagItem)
    res.push({ name: "state", value: EntityState.VERIFICATION, text: "На проверке", group: "state" } as ITagItem)
    res.push({ name: "state", value: EntityState.NOT_SUBMITTED, text: "Не поданные", group: "state" } as ITagItem)
    return res
}

const sortDirs: ComboKeyText<EntitySortDir>[] = [
    [EntitySortDir.CREATE_ASC, strings.sort_create_asc],
    [EntitySortDir.CREATE_DESC, strings.sort_create_desc],
]

interface EntitiesViewProps {
    contest: IContest
}

export function EntitiesView({ contest }: EntitiesViewProps) {
    const contestId = contest.id || ""
    const entityModal = useModal<IContestEntity>()
    const [sort, setSort] = useGlobalState<EntitySortDir>("EntitiesView.sort", EntitySortDir.CREATE_ASC)
    const entities = useContestEntities(contestId, sort)
    const [allFilters] = useState<ITagItem[]>(() => buildFilters(contest))
    const [search, setSearch] = useGlobalState("EntitiesView.search", "")
    const [filters, setFilters] = useState<ITagItem[]>([])

    function saveEditContestEntityHandler(nextContestant: IContestEntity) {
        entities.reload()
        entityModal.closeModal()
    }
    return (
        <>
            <div className="flex flex-row justify-between items-center">
                <button className="textbutton w-min px-2" onClick={() => entityModal.openModal({} as IContestEntity)}>
                    {strings.button_add_contest_entity}
                </button>
                <TextInput type={TextInputType.text}
                    label={null}
                    placeholder={strings.lab_search}
                    value={search} setValue={setSearch}
                />
            </div>
            <LabelWith text={strings.lab_filter} >
                <div className="flex flex-row gap-2 items-center">
                    <Combobox2 value={sort} items={sortDirs} setValue={setSort} />
                    <IconButton name="refresh" onClick={() => entities.reload()} />
                    <div className="flex-auto"></div>
                    <TagsView items={allFilters} onChange={items => setFilters(items)} />
                </div>
            </LabelWith>
            <ListItems
                items={entities.items.filter(m =>
                    searchInFields(search, m.title, m.description) && checkFilters(m, filters)
                )}
                // onFilter={ (items, s) => 
                //     items.filter(m => 
                //         searchInFields(s, m.title, m.description) && checkFilters(m, filters)
                //     )
                // }

                render={item =>
                    <ContestEntityRow key={item.id}
                        item={item}
                        contestId={contestId} />
                }
                canMore={entities.canMore}
                onMore={entities.more}
            />
            {!entities.canMore && <BottomLogoView/>}

            {entityModal.modal &&
                <Modal title={strings.dlg_title_edit_entity}
                    onClose={entityModal.closeModal}
                >
                    <CreateContestEntityWithLoadNominations contestId={contestId}
                        item={entityModal.modal}
                        allowDescription={true}
                        fields={contest?.template?.fields}
                        maxNominations={contest?.maxNominations || MAX_NOMINATION_COUNT}
                        onSave={saveEditContestEntityHandler}
                        onCancel={entityModal.closeModal}
                    />
                </Modal>
            }
        </>
    )
}

interface ICreateContestEntityWithLoadNominationsProps {
    contestId: dbid
    item: IContestEntity
    allowDescription: boolean
    maxNominations: number
    fields: ITemplateField[] | undefined
    onSave: (item: IContestEntity) => void
    onCancel?: () => void
}

export function CreateContestEntityWithLoadNominations({ contestId, item, fields, allowDescription, maxNominations, onSave, onCancel }: ICreateContestEntityWithLoadNominationsProps) {
    const nominations = useNominationsAll(contestId)
    return (
        <>
            {nominations.loading ? <Loading />
                : <CreateContestEntity contestId={contestId}
                    item={item}
                    allowDescription={allowDescription}
                    fields={fields}
                    nominations={nominations.items}
                    maxNominations={maxNominations}
                    onSave={onSave}
                    onCancel={onCancel}
                />}
        </>
    );
}
