
type param = string// | null

const PATH_MESSAGES = "/messages"
const PATH_CONTESTS = "/contests"
const PATH_PUBLIC_CONTESTS = "/participate"
const PATH_ENTITIES = "/contestants"
const PATH_STATIC_PAGES = "/pages"
const PATH_PRESENTATION = "/presentation"
const PATH_TABLE = "/table"

const messages_chat = (chatId: param) =>
    `${PATH_MESSAGES}/chat/${chatId}`
const message_by_id = (id: param) =>
    `${PATH_MESSAGES}/${id}`
const contest_by_id = (id: param, tab?: number) =>
    `${PATH_CONTESTS}/${id}${tab ? `?tab=${tab}` : ""}`
const entity_by_id = (contestId: param, id: param) =>
    `${PATH_ENTITIES}/${contestId}/${id}`
const static_pages_by_id = (contestId: param, id: param) =>
    `${PATH_STATIC_PAGES}/${contestId}/${id}`
const public_static_pages = (id: param) =>
    `p/${id}`
const public_contest_by_id = (id: param) =>
    `${PATH_PUBLIC_CONTESTS}/${id}`

const pptx_template = (contestId: param, id: param) =>
    `/pptx/template/${contestId}/${id}`
// id ? `/pptx/template/${contestId}/${id}` : `/pptx/template/${contestId}`

const xlsx_template = (contestId: param, id: param) =>
    `/xlsx/template/${contestId}/${id}`
// id ? `/xlsx/template/${contestId}/${id}` : `/xlsx/template/${contestId}`

const paths = {
    home: "/",
    news: "/news",
    debug: "/debug",
    login: "/login",
    contact: "/contact",
    about: "/about",
    admin: "/admin",
    user: "/user",
    dashboard: "/dashboard",
    presentation: PATH_PRESENTATION,
    contest_for_jury: "/contest",
    contest_entity_by_id_for_jury: (id: param) => `${PATH_ENTITIES}/${id}`,

    presentation_by_id: (id: param) => `${PATH_PRESENTATION}/${id}`,
    show_presentation: (id: param) => `${PATH_PRESENTATION}/show/${id}`,

    edit_presentation: (id: param) => `${PATH_PRESENTATION}/edit/${id}`,
    edit_table: (id: param) => `${PATH_TABLE}/edit/${id}`,

    messages: PATH_MESSAGES,
    messages_chat: messages_chat,
    message_by_id: message_by_id,
    contest_by_id: contest_by_id,
    contest_entity_by_id: entity_by_id,
    static_pages_by_id: static_pages_by_id,
    public_contest_by_id: public_contest_by_id,
    public_static_pages: public_static_pages,

    pptx_template: pptx_template,
    xlsx_template: xlsx_template,

    // tab_desrc: 0,
    // tab_fields: 1,
    // tab_docs: 2,
    // tab_
}
export default paths