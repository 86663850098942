import { LabelWith } from "../../comp/Label"
import { ListUseItems } from "../../comp/ListItems"
import { usePresentations, useSlideTemplates, useTables } from "../../hooks/useItems"
import { IContest, dbid } from "../../model/models"
import strings from "../../strings"
import { useModal } from "../../hooks/useModal"
import { Modal } from "../../comp/Modal"
import { IPresentation, ISlide } from "../../model/pptx"
import { IDocumentHeader, IXlsxDoc, buildRows, calcMaxColumns } from "../../model/xlsx"
import { IDataQuery } from "../../model/query"
import DocService from "../../services/DocService"
import { CreatePresentationForm } from "../EditPresentationPage/forms/CreatePresentationForm"
import { useTocData } from "../../hooks/useTocData"
import { Link } from "react-router-dom"
import paths from "../../paths"
import ContextMenuButton from "../../comp/ContextMenuButton"
import menus from "../../menus"
import { IContextMenuItem } from "../../comp/ContextMenu"
import { TextInput, TextInputType } from "../../comp/TextInput"
import { useState } from "react"
import { searchInFields } from "../../extends/string"
import { BottomLogoView } from "../../comp/LogoView"

interface DocumentsViewProps {
    contest: IContest
    contestId: dbid
}

export default function DocumentsView({ contest, contestId }: DocumentsViewProps) {
    const [search, setSearch] = useState<string>("")
    const { toc, loading } = useTocData(contestId)
    const presentations = usePresentations(contestId)
    const tables = useTables(contestId)
    const slideTemplates = useSlideTemplates(contestId)

    const createPresentation = useModal<IPresentation>()

    function createImagesZipHandler() {
        global.alert("Not implemented yet!")
    }
    function createPresentationHandler() {
        createPresentation.openModal({ id: "", name: contest?.title, contestId } as IPresentation)
    }
    async function createTableHandler() {
        if (!toc || !contest) {
            return
        }
        let name = global.prompt(strings.prompt_enter_title)
        if (!name) return
        let query = { nominationIds: [], entityQueries: [] } as IDataQuery
        let rows = buildRows(toc, query, undefined, undefined, true)
        let doc = {
            id: Date.now() + "",
            contestId, name, query, created: Date.now(),
            rows: rows.rows,
            templates: rows.templates,
            maxColumn: calcMaxColumns(rows.rows),
            header: true
        } as IXlsxDoc

        try {
            const resp = await DocService.createTable(doc)
            tables.addItem(resp.data)
            // setCurrentXlsx(doc)
        } catch (e) {
            console.error(e)
        }
    }

    async function deletePresentationHandler(item: IDocumentHeader) {
        try {
            const resp = await DocService.deletePresentation(item.id)
            if (resp.statusText === "OK")
                presentations.removeItem(item)
        } catch (e) {
            console.error(e)
        }
    }
    async function clonePresentationHandler(item: IDocumentHeader) {
        try {
            let name = global.prompt(strings.prompt_enter_title, item.name)
            if (!name)
                return
            name = name.trim()
            if (presentations.items.find(item => item.name === name)) {
                global.alert(`Презентация с имененм "${name}" уже существует`)
                return
            }
            let data = {
                id: "", name, contestId: item.contestId,
                created: Date.now(),
                query: {} as IDataQuery,
                slides: [], templates: []
            } as IPresentation
            const resp = await DocService.clonePresentation(item.id, data)
            if (resp.statusText === "OK")
                presentations.addItem(resp.data, true)
        } catch (e) {
            console.error(e)
        }
    }
    function submitPresentationHandler(presentation: IPresentation) {
        let created = Date.now()
        presentations.addItem({
            id: presentation.id, name: presentation.name, description: presentation.description,
            created, lastEdit: created
        } as IDocumentHeader)
    }
    async function deleteTableHandler(table: IDocumentHeader) {
        try {
            const resp = await DocService.deleteTable(table.id)
            if (resp.statusText === "OK") {
                tables.removeItem(table)
            }
        } catch (e) {
            console.error(e)
        }
    }

    async function createSlideTemplateHandler() {
        let name = global.prompt("Название слайда")
        if (!name)
            return
        try {
            const resp = await DocService.createSlideTemplate({ id: "", contestId, name, fields: [] })
            slideTemplates.addItem(resp.data)
        } catch (e) {
            console.error(e)
        }
    }
    function openSlideTemplate(slide: ISlide) {

    }
    async function deleteSlideTemplate(slide: ISlide) {
        try {
            const resp = await DocService.deleteSlideTemplate(slide.id)
            if (resp.statusText === "OK")
                slideTemplates.removeItem(slide)
        } catch (e) {
            console.error(e)
        }

    }
    return (
        <div className="maincontainer">
            <div className="pb-4 flex flex-row justify-start gap-2">
                <button className="textbutton" disabled
                    onClick={createImagesZipHandler}>{strings.mi_download_arch}</button>
                <button className="textbutton"
                    onClick={createPresentationHandler}>{strings.button_create_presentation}</button>
                <button className="textbutton"
                    onClick={createTableHandler}>{strings.button_create_table}</button>
                {/* <button className="textbutton" 
                    onClick={createSlideTemplateHandler}>{strings.button_create_slide_template}</button> */}
            </div>
            <div className="flex flex-row justify-end">
                <TextInput className="w-2/5"
                    type={TextInputType.text}
                    label={null} placeholder={strings.lab_search}
                    value={search} setValue={setSearch} />
            </div>
            <LabelWith text={strings.lab_presentations} >
                <ListUseItems
                    search={search}
                    useItems={presentations}
                    onFilter={(items, s) =>
                        items.filter(m => searchInFields(s, m.name, m.description))
                    }
                    render={item =>
                        <DocumentRow key={item.id}
                            item={item} isPresentation
                            onDelete={deletePresentationHandler}
                            onClone={clonePresentationHandler} />
                    }
                    nobottom />
            </LabelWith>
            <LabelWith text={strings.lab_tables}>
                <ListUseItems
                    search={search}
                    useItems={tables}
                    onFilter={(items, s) =>
                        items.filter(m => searchInFields(s, m.name, m.description))
                    }
                    render={item =>
                        <DocumentRow key={item.id}
                            item={item} isPresentation={false}
                            onDelete={deleteTableHandler} />
                    }
                    nobottom />
            </LabelWith>
            {/* <LabelWith text={strings.lab_slide_templates}>
                <ListUseItems 
                    useItems={slideTemplates}
                    nobottom
                    render={item => 
                        <RowWithName item={item} 
                            onClick={openSlideTemplate}
                            onDelete={deleteSlideTemplate}/>
                    }/>
            </LabelWith> */}
            <BottomLogoView />

            {(contest && toc && createPresentation.modal) &&
                <Modal title="Создать презентацию"
                    onClose={createPresentation.closeModal}
                    wide>
                    <CreatePresentationForm
                        presentation={createPresentation.modal}
                        contest={contest}
                        toc={toc}
                        onClose={createPresentation.closeModal}
                        onSave={submitPresentationHandler} />
                </Modal>
            }
        </div>
    )
}

type DocumentRowType = IDocumentHeader

const MENU_CLONE = "clone"
const MENU_DOCUMENT_ROW = [menus.delete,
{ id: MENU_CLONE, title: "Копировать" } as IContextMenuItem
]

interface DocumentRowProps {
    item: DocumentRowType
    isPresentation: boolean
    onDelete: (item: DocumentRowType) => void
    onClone?: (item: DocumentRowType) => void
}

export function DocumentRow({ item, isPresentation, onDelete, onClone }: DocumentRowProps) {

    let to = isPresentation ? paths.edit_presentation(item.id) : paths.edit_table(item.id)
    let text = item.name
    let date = new Date(item.lastEdit || item.created).toLocaleString()
    function menuClickHandler(mi: IContextMenuItem) {
        switch (mi.id) {
            case menus.DELETE:
                onDelete(item)
                break
            case MENU_CLONE:
                onClone && onClone(item)
                break
        }
    }
    return (
        <div className="itemrow flex flex-row gap-2 items-center" >

            <Link className="flex flex-col flex-auto" to={to}>
                <span>{text}</span>
                <span className="text-onPrimary/75 italic text-sm">{date}</span>
            </Link>

            <ContextMenuButton
                menu={MENU_DOCUMENT_ROW}
                onClick={menuClickHandler} />
        </div>
    )
}

