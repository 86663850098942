import strings from "../../strings"

export function titleValidation(minLength: number = 3, maxLength: number = 128) {
    return {
        required: true,
        minLength: {
            value: minLength, message: `${strings.msg_invalid_length_min} ${minLength}`
        }, 
        maxLength: {
            value: maxLength, message: `${strings.msg_invalid_length_max} ${maxLength}`
        }
    }
}

export function descrValidation(maxLength: number = 1000) {
    return {
        maxLength: {
            value: maxLength, message: `${strings.msg_invalid_length_max} ${maxLength}`
        }
    }
}

export function loginValidation() {
    return {
        ...titleValidation()
    }
}

export function passwordValidation() {
    return {
        ...titleValidation()
    }
}

export function fieldNameValidation() {
    return { 
        required: true, 
        minLength: {
            value: 3, message: `${strings.msg_invalid_length_min} 3`
        }, 
        maxLength: {
            value: 12, message: `${strings.msg_invalid_length_max} 12`
        },
        pattern: {
            value: /^[A-Za-z]\w+$/,
            message: strings.msg_invalid_romaji
        } 
    }
}

export function emailValidation(required: boolean) {
    return {
        required: required,
        minLength: {
            value: 5, message: `${strings.msg_invalid_length_min} 5`
        },
        pattern: {
            value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
            message: "Некорректный адрес электронной почты"
        } 
    }
}

export function emailValidationNotStrict(required: boolean) {
    return {
        required: required,
        minLength: {
            value: 5, message: `${strings.msg_invalid_length_min} 5`
        },
        pattern: {
            value: /^\s*(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})\s*$/i,
            message: "Некорректный адрес электронной почты"
        } 
    }
}

export function dbidValidation(required: boolean) {
    return {
        required: required,
        minLength: {
            value: 24, message: `${strings.msg_invalid_length_min} 24`
        },
        pattern: {
            value: /^[\da-z]{24}$/,
            message: "Некорректный идентификатор"
        } 
    }
}