import { useState } from "react"
import { INomination, dbid } from "../../model/models"
import strings from "../../strings"
import { Icon } from "../Icon"
import { ListItems } from "../ListItems"
import { NominationsSortDir, useNominations } from "../../hooks/useItems"
import { Loading } from "../Loading"
import { ErrorMessage } from "../ErrorMessage"

interface IEditNominationsContestEntityWithLoadProps {
    contestId: dbid
    nominationIds: dbid[]
    maxNominations: number
    onSave: (next: dbid[], nominations: INomination[]) => void//next: 
    onCancel?: () => void
}

export function EditNominationsContestEntityWithLoad({ contestId, nominationIds, maxNominations, onSave, onCancel }: IEditNominationsContestEntityWithLoadProps) {
    const nominations = useNominations(contestId, NominationsSortDir.CREATE_ASC);

    if (nominations.loading) {
        return <Loading />
    } else {
        return <EditNominationsContestEntity
            nominations={nominations.items}
            nominationIds={nominationIds}
            maxNominations={maxNominations}
            onSave={onSave} onCancel={onCancel}
        />
    }
}

interface IEditNominationsContestEntityProps {
    // contestId: dbid
    // entityId: dbid
    style?: React.CSSProperties
    submitStyle?: React.CSSProperties
    cancelStyle?: React.CSSProperties
    nominations: INomination[]
    nominationIds: dbid[]
    maxNominations: number
    onSave: (next: dbid[], nominations: INomination[]) => void//next: INomination[]
    onCancel?: () => void
}

export function EditNominationsContestEntity({ style, submitStyle, cancelStyle, nominations, nominationIds, maxNominations, onSave, onCancel }: IEditNominationsContestEntityProps) {
    // const [loading, setLoading] = useState(false)
    const [ids, setIds] = useState(nominationIds)
    const [filter, setFilter] = useState("")

    function toggleNomination(n: INomination) {
        if (n.id === undefined) {
            return
        } else if (ids.indexOf(n.id) >= 0) {
            console.log("toggleNomination remove", n)
            setIds(ids.filter(id => id !== n.id))
        } else {
            console.log("toggleNomination set", n)
            setIds([...ids, n.id])
        }
    }
    async function saveHandler() {
        const items = nominations.filter(e => ids.indexOf(e.id) >= 0);
        onSave(ids, items)
    }
    async function clickItem(nom: INomination) {
        onSave([nom.id], [nom])
    }
    return (
        <div className="flex flex-col" style={style}>
            {ids.length == 0
                ? <ErrorMessage message={strings.errorMinNominations} />
                : ids.length > maxNominations
                && <ErrorMessage message={strings.errorMaxNominations(maxNominations)} />
            }
            {maxNominations == 1
                ? <ListItems items={nominations}
                    // onFilter={ (items, s) => 
                    //     items.filter(m => 
                    //         m.title.toLocaleLowerCase().indexOf(s.toLocaleLowerCase()) >= 0 
                    //     )
                    // }
                    render={(nom) =>
                        <div className="px-4 py-2 select-none" onClick={e => clickItem(nom)}>
                            {nom.title}
                        </div>
                    } />
                : <ListItems items={nominations}
                    // onFilter={ (items, s) => 
                    //     items.filter(m => 
                    //         m.title.toLocaleLowerCase().indexOf(s.toLocaleLowerCase()) >= 0 
                    //     )
                    // }
                    render={(nom) =>
                        <CheckboxRow key={nom.id} text={nom.title}
                            checked={ids.indexOf(nom.id) >= 0}
                            onChange={() => toggleNomination(nom)} />
                    } />
            }


            <div className='flex flex-row justify-end gap-2 pt-4'>
                <button type='submit' className='greenbutton'
                    style={submitStyle}
                    disabled={ids.length > maxNominations}
                    onClick={saveHandler}
                >{strings.button_save}</button>
                {onCancel &&
                    <button type='reset' className='textbutton'
                        style={cancelStyle}
                        onClick={onCancel}>{strings.button_cancel}</button>
                }
            </div>
        </div>
    )
}

interface CheckboxRowProps {
    checked: boolean
    text: string
    onChange: () => void
}

function CheckboxRow({ checked, text, onChange }: CheckboxRowProps) {
    // console.log("REcompose ", text, checked)
    return (
        <div className="py-2 flex items-center gap-2" onClick={onChange}>
            {/* <input type="checkbox" className="checkbox"
                checked={checked}
                onChange={onChange}
                /> */}
            <Icon name={checked ? "check_box" : "check_box_outline_blank"} className="cursor-pointer" />
            <span className="select-none">{text}</span>
        </div>
    )
}