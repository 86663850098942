import { LogoView } from "../comp/LogoView";
import { useTitle } from "../hooks/useTitle";
import strings from "../strings";

export function AboutPage() {
    useTitle(strings.nav_about)

    return (
        <div className='maincontainer flex flex-col gap-y-32 items-center pb-8'>

            <AboutItemView align='right'
                title='Проведем конкурс вместе'
                text='Сайт “Городские конкурсы” это Ваш помошник в организации данных, формирования презентаций, таблиц и голосования жюри.'></AboutItemView>
            <AboutDivLine />

            <AboutItemView align='right'
                title='Организованное проведение конкурсов'
                text='Единый центр обработки поступающих заявок (информации, картинок, файлов согласия на обработку личных данных).'>
                <MacosWindowView>
                    <img src='/img/home-objects.png' />
                </MacosWindowView>
            </AboutItemView>

            <AboutDivLine />

            <AboutItemView align='center'
                title='Любой конкурс - это гигантсое количество документов'
                text='Легкий экспорт презентаций, таблиц, архивов и др.  Встроенный редактор презентаций позволит создать презентацию в несколько кликов. Список участников с номерами телефонов, ИНН или результаты голосования.'>

                <div className='flex flex-row justify-center gap-4 font-bold font-mono text-3xl'>
                    <div className='bg-primary3 rounded-lg px-4 py-2'>PDF</div>
                    <div className='bg-primary3 rounded-lg px-4 py-2'>PPTX</div>
                    <div className='bg-primary3 rounded-lg px-4 py-2'>XLSX</div>
                    <div className='bg-primary3 rounded-lg px-4 py-2'>ZIP</div>
                </div>

            </AboutItemView>

            <AboutDivLine />

            <AboutItemView align='left'
                title='Подведение результатов голосования'
                text='Конкурс - это несколько стадий предварительного отсева участников. Каждый голос должен быть учтен. Для каждой стадии конкурса жюри, презентация участников, таблица результатов...'>
                {/* <MacosWindowView>
                    <img src='/img/home-voteresult.png'/>
                </MacosWindowView> */}
            </AboutItemView>

            <AboutDivLine />

            <AboutItemView align='center'
                title='Ограниченный доступ для членов жюри'
                text='Организация авторизованного доступа для жюри по уникальной ссылке. Возможность просмотра учасников, презентации и голосования.'>
                <div className='flex flex-row justify-center gap-4 items-start'>
                    <img className='max-w-[300px]' src='/img/home-jurylist.png' />
                    <img className='max-w-[300px]' src='/img/home-jurylogin.png' />
                </div>
            </AboutItemView>


        </div>
    );
}

interface AboutItemViewProps {
    title: string
    text: string
    align: 'left' | 'center' | 'right'
    children?: React.ReactNode
}

function AboutItemView({ title, text, align, children }: AboutItemViewProps) {
    let textAlign = align === 'center' ? 'right' : align
    return (
        <div className='flex flex-row gap-8 items-center'>
            {align === 'left' && children}
            <div className='flex flex-col gap-8 max-w-[500px]'>
                <div className={`text-5xl text-${textAlign}`}>{title}</div>
                <div className='text-sm'>{text}</div>
                {align === 'center' && children}
            </div>
            {align === 'right' && children}
        </div>
    )
}

interface MacosWindowViewProps {
    children?: React.ReactNode
}

function MacosWindowView({ children }: MacosWindowViewProps) {
    let bgColor = "#1E293B"//"primary3"
    let color = "#354154"//"primary2"
    return (
        <div className="rounded-lg drop-shadow min-w-[100px] min-h-[30px] flex flex-col"
            style={{ background: bgColor }}>
            <div className="h-[30px] flex flex-row items-center px-[12px] gap-[4px]"
                style={{ borderBottom: "1px solid " + color }}>
                <div className="rounded-full w-[10px] h-[10px]" style={{ background: color }}></div>
                <div className="rounded-full w-[10px] h-[10px]" style={{ background: color }}></div>
                <div className="rounded-full w-[10px] h-[10px]" style={{ background: color }}></div>
            </div>
            {children}
        </div>
    )
}

function AboutDivLine() {
    return (
        <div className='flex flex-row items-center w-full'>
            <div className='flex-auto bg-disabled h-[1px]'></div>
            <LogoView disabled />
            <div className='flex-auto bg-disabled h-[1px]'></div>
        </div>
    )
}