import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useTypedSelector } from '../hooks/useTypedSelector';
import { ISubmitter, IUser, PhotoSize } from "../model/models";
import paths from '../paths';
import { logoutAction } from "../store/actions/auth";
import strings from '../strings';
import { AvatarView } from "./AvatarView";
import { ContextMenuDir, IContextMenuItem } from "./ContextMenu";
import ContextMenuButton from "./ContextMenuButton";
import { LogoView } from './LogoView';
import { extractImageSrc } from "./ImageFB";

const mainMenu = [
    { id: paths.home, title: strings.nav_main },
    { id: paths.news, title: strings.nav_news },
    { id: paths.admin, title: strings.nav_admin },
    { id: paths.presentation, title: strings.nav_presentation },
    { id: paths.contest_for_jury, title: strings.nav_contest },
    { id: paths.dashboard, title: strings.nav_dashboard },
    { id: paths.messages, title: strings.nav_messages },
    // { id: paths.about, title: strings.nav_about },
] as IContextMenuItem[]

interface INavigationProps {
    // style?: React.CSSProperties
    logo?: string
    title: string
    titleSmall: string
    background?: string
    color?: string
}

export function Navigation({ logo, title, titleSmall, background, color }: INavigationProps) {
    const state = useTypedSelector(state => state.auth);
    const navigate = useNavigate()

    function onMiVisibe(mi: IContextMenuItem) {
        switch (mi.id) {
            case paths.presentation: return !!state.jury
            case paths.dashboard: return !!state.user
            case paths.messages: return !!state.user
            case paths.contest_for_jury: return !!state.jury
            case paths.admin: return !!state.user?.isSiteAdmin
            default: return true
        }
    }

    return (
        <nav className='flex flex-col md:flex-row justify-between py-2 px-2 md:px-big bg-primary3 text-onPrimary3 gap-x-2'
            style={{ background: background }}>
            <div className='flex flex-row items-center'>
                <Link to={paths.home} className="flex-none0">
                    <LogoView logo={logo} />
                </Link>
                <Link to={paths.home} >
                    <AppNameView title={title} titleSmall={titleSmall} />
                </Link>
                <div className="flex-auto"></div>
                <LoginLink className="block md:hidden ml-1"
                    background={background} />
                <ContextMenuButton className="mx-2 block md:hidden transparent big"
                    color={color}
                    background={background}
                    icon="menu" dir={ContextMenuDir.FromRightTop}
                    onVisible={onMiVisibe}
                    menu={mainMenu}
                    onClick={mi => navigate(mi.id)} />
            </div>

            <div className='hidden md:flex gap-x-4 flex-row flex-wrap justify-end items-center'>
                <Link to={paths.home} className='linkbutton'>{strings.nav_main}</Link>
                <Link to={paths.news} className='linkbutton'>{strings.nav_news}</Link>
                {state.user?.isSiteAdmin &&
                    <Link to={paths.admin} className='linkbutton'>{strings.nav_admin}</Link>
                }
                {(state.jury?.presentationId) &&
                    <Link to={paths.presentation} className='linkbutton'>{strings.nav_presentation}</Link>
                }
                {(state.jury?.query) &&
                    <Link to={paths.contest_for_jury} className='linkbutton'>{strings.nav_contest}</Link>
                }
                {state.user &&
                    <>
                        <Link to={paths.dashboard} className='linkbutton'>{strings.nav_dashboard}</Link>
                        <Link to={paths.messages} className='linkbutton'>{strings.nav_messages} {state.user?.messages || ""}</Link>
                    </>
                }
                <LoginLink className="hidden md:block"
                    background={background} />
                {/* <Link to={paths.about} className='linkbutton'>{strings.nav_about}</Link> */}
            </div>

        </nav>
    );
}

interface ILoginLinkProps {
    className?: string
    color?: string
    background?: string
}

function LoginLink({ className, background, color }: ILoginLinkProps) {
    const state = useTypedSelector(state => state.auth);
    if (state.user)
        return <UserNameView className={className} color={color} background={background}
            user={state.user} />
    else if (state.jury)
        return <UserNameView className={className} color={color} background={background}
            user={state.jury} />
    else if (state.submitter)
        return <SubmitterView className={className} color={color} background={background}
            user={state.submitter} />;
    else
        return (
            <Link to={paths.login} className={'textbutton ' + (className || "")}
                style={{ background, color }}>
                {strings.nav_login}
            </Link>
        );
}

interface IUserNameViewProps {
    color?: string
    background?: string
    className?: string
    user: IUser
}

function UserNameView0({ className, color, background, user }: IUserNameViewProps) {
    const text = user.juryContestId ? strings.lab_jury : strings.lab_user
    return (
        <Link to={paths.user} className={'textbutton ' + (className || "")}
            style={{
                color, background, //paddingLeft: 0, paddingTop: 0, paddingBottom: 0 
            }}>
            <div className="flex flex-row items-center gap-1 max-w-[150px]">
                {user.avatar &&
                    <AvatarView size={PhotoSize.small} src={user.avatar} sizePx="32px" className="flex-none0" />
                }
                <div className="flex flex-col justify-end items-end overflow-hidden">
                    <div className="leading-tight line-clamp-2 w-min">
                        <span>{user.fullname || user.username || user.email}</span>
                    </div>
                    <span className="leading-none text-[10px] text-disabled">{text}</span>
                </div>
            </div>
        </Link>
    );
}

function UserNameView({ className, color, background, user }: IUserNameViewProps) {
    const text = user.juryContestId ? strings.lab_jury : strings.lab_user
    var src = user.avatar && extractImageSrc(user.avatar, PhotoSize.small)

    return (
        <Link to={paths.user} className={'textbutton overflow-hidden ' + (className || "")}
            style={{
                color, background, paddingLeft: 0, paddingTop: 0, paddingBottom: 0
            }}>
            <div className="relative flex flex-row items-center gap-2 max-w-[150px] h-[50px]">
                {src &&
                    <img src={src} className="rounded-full aspect-square object-cover h-[60px]"
                        style={{ marginLeft: "-10px", marginTop: "-7px", marginBottom: "-7px" }} />
                }
                <div className="leading-[16px] line-clamp-2 w-min text-[14px] text-right mt-[2px] mb-[12px] overflow-hidden">
                    <span>{user.fullname || user.username || user.email}</span>
                </div>
                <span className="absolute leading-none text-[9px] text-white/60 z-40"
                    style={{
                        right: 0, bottom: "4px"
                    }}>{text}</span>
            </div>
        </Link>
    );
}

interface ISubmitterViewProps {
    className?: string
    color?: string
    background?: string
    user: ISubmitter
}

function SubmitterView({ className, color, background, user }: ISubmitterViewProps) {
    const dispatch = useAppDispatch()
    return (
        <div className={"textbutton flex flex-row items-center gap-2 max-w-[150px] " + (className || "")}
            style={{
                color, background
            }}
            onClick={() =>
                global.confirm(strings.msg_logout) && dispatch(logoutAction())
            }>
            <div className="flex flex-col justify-end items-end overflow-hidden">
                <div className="leading-tight line-clamp-2 w-min">
                    <span>{user.email}</span>
                </div>
                <span className="leading-none text-[10px] text-disabled">{strings.lab_submitter}</span>
            </div>
        </div>
    );
}

export function AppNameView({ title, titleSmall, className }: { title: string, titleSmall: string, className?: string }) {
    return (
        <div className={'flex flex-col sitename ' + (className ? className : "")}>
            <span className='font-bold text-xl self-end md:text-3xl'>{title}</span>
            <span className='text-xs font-thin md:text-sm self-end whitespace-nowrap'
                style={{ marginTop: "-6px" }}>{titleSmall}</span>
        </div>
    )
}