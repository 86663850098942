import { DragItemHandler } from "../../hooks/useDrag"
import { ITemplateField } from "../../model/models"
import strings from "../../strings"
import ContextMenuButton from "../ContextMenuButton"
import { IContextMenuItem } from "../ContextMenu"
import menus from "../../menus"

interface TemplateFieldRowProps {
    field: ITemplateField
    // draggable?: boolean
    onDrag?: DragItemHandler<ITemplateField>
    onClick?: () => void
    onDelete?: () => void
}

const menu = [ menus.delete]

export default function TemplateFieldRow(props: TemplateFieldRowProps) {
    function contextMenuHandler(mi: IContextMenuItem) {
        // console.log(id, props.field.label)
        switch (mi.id) {
            case menus.DELETE: 
                props.onDelete && props.onDelete()
                break;
        }
    }
    return (
        <div className="itemrow flex flex-row justify-between"
            onClick={props.onClick} 
            onDragStart={ e => props.onDrag?.onStart && props.onDrag.onStart(props.field) }
            onDragEnter={ e => props.onDrag?.onEnter && props.onDrag.onEnter(props.field) }
            onDragEnd={ e => props.onDrag?.onEnd && props.onDrag.onEnd(props.field)}
            draggable={!!props.onDrag}
        >
            <div className="flex flex-col">
                <span className={ props.field.deprecated ? "text-disabled" : "text-onPrimary"}>{props.field.label}{props.field.required && " (" + strings.msg_required + ")"}</span>
                <span className="text-sm text-onPrimary/[.5]">{props.field.type.toLowerCase()}</span>
            </div>
            <ContextMenuButton 
                menu={menu} 
                onClick={contextMenuHandler}
                />
        </div>
    )
}
