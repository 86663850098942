import { AuthAction, AuthActionTypes, AuthReducerState } from "../../model/auth";
import { ISubmitter, IUser } from "../../model/models"

const KEY_USER = "user"
const KEY_JURY = "jury"
const KEY_SUBMITTER = "submitter"

export const KEY_TOKEN = "token"

function readUser(key: string) {
    let s = localStorage.getItem(key)
    if (s !== undefined && s !== null) {
        try {
            return JSON.parse(s) as IUser
        } catch (e) {
            return undefined//{} as IUser
        }
    } else
        return undefined//{} as IUser
}
function readSubmitter(key: string) {
    let s = localStorage.getItem(key)
    if (s !== undefined && s !== null) {
        try {
            return JSON.parse(s) as ISubmitter
        } catch (e) {
            return undefined//{} as IUser
        }
    } else
        return undefined//{} as IUser
}

let initUser = readUser(KEY_USER)
let initJury = readUser(KEY_JURY)
let initSubmitter = readSubmitter(KEY_SUBMITTER)

const initialState: AuthReducerState = {
    loading: false,
    isAuth: !!(initUser && initUser.email) || !!(initJury && initJury.fullname),
    user: initUser,
    jury: initJury,
    submitter: initSubmitter,
    error: null
}

export const authReducer = (state: AuthReducerState = initialState, action: AuthAction): AuthReducerState => {
    switch (action.type) {
        case AuthActionTypes.AUTHORIZATION:
            return { ...state, loading: true };

        case AuthActionTypes.SUCCESS:
            let user: IUser | undefined
            let jury: IUser | undefined
            let submitter: ISubmitter | undefined
            if (action.payload.juryContestId) {
                // console.log("Login or update user success ", action.payload)
                jury = action.payload
                localStorage.removeItem(KEY_USER)
                localStorage.setItem(KEY_JURY, JSON.stringify(action.payload))
            } else if (action.payload.id) {
                // console.log("Login or update user success ", action.payload)
                user = action.payload
                localStorage.removeItem(KEY_JURY)
                localStorage.setItem(KEY_USER, JSON.stringify(action.payload))
            } else if (action.payload.email) {
                submitter = { email: action.payload.email };
                localStorage.setItem(KEY_SUBMITTER, JSON.stringify(action.payload))
            }

            return {
                loading: false,
                isAuth: user != null || jury != null,
                user, jury, submitter,
                error: null
            };

        case AuthActionTypes.ERROR:
            // localStorage.removeItem(KEY_USER)
            localStorage.removeItem(KEY_JURY)
            localStorage.removeItem(KEY_TOKEN)
            return { loading: false, isAuth: false, user: undefined, jury: undefined, error: action.payload };

        case AuthActionTypes.LOGOUT:
            localStorage.removeItem(KEY_USER)
            localStorage.removeItem(KEY_JURY)
            localStorage.removeItem(KEY_SUBMITTER)
            localStorage.removeItem(KEY_TOKEN)
            return { loading: false, isAuth: false, user: undefined, jury: undefined, submitter: undefined, error: null };

        default:
            return state;
    }
}