import React, { useState } from "react";
import { FieldError } from "react-hook-form";
import { IAttachmentFile, ITemplateField, dbid } from "../../model/models";
import { AttachmentFileRow } from "../../pages/ContestEntityPage";
import DataService from "../../services/DataService";
import strings from "../../strings";
import { FileUploader } from "../FileUploader";
import { ImgUploader } from "../ImgUploader";
import { LabelWith } from "../Label";
import { Loading } from "../Loading";

interface EditFileFieldProps {
    className?: string
    labelClassName?: string
    labelStyle?: React.CSSProperties
    buttonStyle?: React.CSSProperties
    contestId: dbid
    entityId?: dbid
    value?: string
    error?: FieldError
    onChange?: (e: any) => void
    onBlur?: () => void
    label: string
    hint?: string
    required?: boolean
}

export function EditFileField({ className, labelClassName, labelStyle, buttonStyle, contestId, entityId, value, error, onChange, onBlur, label, hint, required }: EditFileFieldProps) {
    const [uploading, setUploading] = useState(false)
    // const imgFullscreen = useImageFullscreen()
    const fileObj = value && JSON.parse(value) as IAttachmentFile

    function updateValue(nextFile: IAttachmentFile | null) {
        const nextValue = nextFile ? JSON.stringify(nextFile) : undefined
        onChange && onChange(nextValue)
    }

    async function sendFile(file: File) {
        if (!file || !onChange) {
            return
        }
        setUploading(true)
        try {
            const resp = await DataService.uploadDoc(file, contestId, entityId);
            updateValue(resp.data)
        } catch (e) {
            console.error(e)
        } finally {
            setUploading(false)
        }
    }
    async function sendFiles(files: FileList) {
        let file = files[0]
        file && onChange && sendFile(file)
    }
    function dropFile(files: File[]) {
        let file = files[0]
        file && onChange && sendFile(file)
    }

    function clearHandler() {
        if (!onChange || !global.confirm(strings.confirm_delete_photo)) {
            return
        }
        onBlur && onBlur()
        updateValue(null)
    }

    return (
        <LabelWith text={label} error={error}
            className={className} labelClassName={labelClassName}
            labelStyle={labelStyle}>
            <div className="flex flex-col">
                {hint && 
                <div className="text-sm hint" dangerouslySetInnerHTML={{ __html: hint }}></div>
                }
                <FileUploader sendFile={dropFile} >
                    <div className="relative w-full min-h-[100px] max-h-[200px] overflow-hidden">
                        {fileObj ?
                            <AttachmentFileRow file={fileObj} />
                            : <div className="text-center text-sm py-[10px]"
                                style={{ opacity: 0.5 }}>
                                {strings.msg_empty_file}
                            </div>
                        }
                        {onChange &&
                            <div className="absolute-center0 flex flex-col gap-y-2 text-center justify-center items-center">
                                {uploading && <Loading />}
                                {fileObj &&
                                    <button className="textbutton text-sm"
                                        style={buttonStyle}
                                        onClick={clearHandler}>{strings.mi_delete}</button>
                                    // : <span className="text-center">{strings.msg_empty_file}</span>
                                }
                                {/* <button className="textbutton" onClick={}>{strings.button_load}</button> */}
                                <ImgUploader onUpload={sendFiles}
                                    buttonStyle={buttonStyle} />
                            </div>
                        }
                    </div>
                </FileUploader>
            </div>
        </LabelWith>
    )
}

