import { FieldError } from "react-hook-form"
import strings from "../strings"

interface LabelProps {
    text: string
    error?: FieldError
    requiredMessage?: string
    disabled?: boolean
    className?: string
    style?: React.CSSProperties
}

export function Label({ text, error, requiredMessage, disabled, className, style }: LabelProps) {
    const errMessage = error && (error.message || (error.type === "required" ? requiredMessage || strings.msg_required : ""))
    // console.log(text + ": ", errMessage, error)
    return (
        <label
            className={`select-none ${className || `font-light flex flex-wrap pt-2 text-sm text-onPrimary/${disabled ? "50" : "75"}`}`}
            style={style}
        >{text}
            {error &&
                <div className="bg-error text-onError mx-1 px-2 mb-1 rounded text-ellipsis"
                    title={errMessage}>
                    {errMessage}
                </div>}
        </label>
    )
}

interface LabelWithProps {
    text: string
    error?: FieldError
    requiredMessage?: string
    disabled?: boolean
    className?: string
    labelClassName?: string
    labelStyle?: React.CSSProperties
    children: React.ReactNode
}

export function LabelWith(props: LabelWithProps) {
    return (
        <div className={`flex flex-col ${props.className ? props.className : ""}`}>
            <Label
                className={props.labelClassName}
                style={props.labelStyle}
                text={props.text}
                error={props.error} requiredMessage={props.requiredMessage}
                disabled={props.disabled} />
            {props.children}
        </div>
    )
}