import { Link } from 'react-router-dom';
import strings from '../strings';
import paths from '../paths';

interface IFooterProps {
    style?: React.CSSProperties
}

export function Footer({ style }: IFooterProps) {
    return (
        <div className='p-8 md:p-big flex flex-row justify-between items-center bg-primary3 text-disabled gap-x-4'
            style={style}>
            <p className='flex-auto'>{strings.footer}</p>
            <div className='flex flex-col justify-around md:flex-row items-center gap-2'>
                <Link to={paths.contact} className='md:flex-none whitespace-nowrap'>{strings.nav_contact}</Link>
                <Link to={paths.about} className='md:flex-none whitespace-nowrap'>{strings.nav_about}</Link>
            </div>
        </div>
    );
}
