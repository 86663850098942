import { AxiosError } from "axios";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ErrorMessage } from "../comp/ErrorMessage";
import { CreateContestEntity } from "../comp/forms/CreateContestEntity";
import { emailValidation, emailValidationNotStrict, passwordValidation } from "../comp/forms/validations";
import { LabelWith } from "../comp/Label";
import { Loading } from "../comp/Loading";
import { Modal } from "../comp/Modal";
import { TabsView } from "../comp/TabsView";
import { parseStyleCss } from "../extends/css";
import { useStaticPage } from "../hooks/useItems";
import { useModal } from "../hooks/useModal";
import { useAppDispatch, useTypedSelector } from "../hooks/useTypedSelector";
import { dbid, IContestEntity, MAX_NOMINATION_COUNT } from "../model/models";
import { SubmitterService } from "../services/SubmitterService";
import { submitterLoginAction } from "../store/actions/auth";
import strings from "../strings";
import { PromotedPage } from "./promoted/PromotedPage";
import { ILoginForm } from "./views/LoginTab";

interface IPublicStaticPageProps {
    staticPageId?: string
    headerFooterOff?: boolean
    checkRegistrationPeriod?: boolean
}

export function PublicStaticPage({ staticPageId, headerFooterOff, checkRegistrationPeriod }: IPublicStaticPageProps) {
    var pageId = useParams().id || "0";
    if (staticPageId) {
        pageId = staticPageId;
    }
    const { submitter, user } = useTypedSelector(state => state.auth);

    const { staticPage, loading, error } = useStaticPage(pageId, true);

    const submitModal = useModal<IContestEntity>()
    const gratitudeModal = useModal<boolean>()

    function onSave(entity: IContestEntity) {
        submitModal.closeModal()
        gratitudeModal.openModal(true)
    }
    function registerHandler() {
        if (checkRegistrationPeriod) {
            const time = Date.now()
            if (staticPage?.registrationStart && time < staticPage?.registrationStart) {
                alert("Регистрация еще не открыта!")
                return;
            }
            if (staticPage?.registrationFinish && time > staticPage?.registrationFinish) {
                alert("Регистрация уже закрыта!")
                return;
            }
        }
        submitModal.openModal({} as IContestEntity)
    }
    if (loading) {
        return <Loading />;
    } else if (staticPage) {
        const dialogStyle = parseStyleCss(staticPage.dialogStyle)
        const formStyle = parseStyleCss(staticPage.formStyle)
        const submitStyle = parseStyleCss(staticPage.submitStyle)
        return (
            <div>
                <PromotedPage logo={staticPage.formLogo}
                    headerFooterOff={headerFooterOff}
                    onRegisterClick={registerHandler} />
                {submitModal.modal &&
                    <Modal title={staticPage.formTitle ? undefined : strings.dlg_title_submit_entity} disableDoubleClickClose
                        style={dialogStyle} buttonStyle={{ background: submitStyle?.background }}
                        onClose={submitModal.closeModal}>
                        <ProtectedSubmitter contestId={staticPage.contestId}
                            buttonStyle={submitStyle}>
                            <TabsView >
                                {staticPage.forms?.map(form =>
                                    <div title={form.title} key={form.title} >
                                        <CreateContestEntity contestId={staticPage.contestId}
                                            fields={form.fields}
                                            nominations={form.nominations || []}
                                            item={submitModal.modal!!}

                                            style={{ ...formStyle, borderTopLeftRadius: 0, borderTopRightRadius: 0, }}
                                            dialogStyle={dialogStyle}
                                            labelStyle={parseStyleCss(staticPage.fieldsLabelStyle)}
                                            fieldClassName={staticPage.fieldsClassName}
                                            logo={staticPage.formLogo}
                                            title={staticPage.formTitle}
                                            titleLabel={form.titleLabel}
                                            titleHint={form.titleHint}
                                            submitStyle={submitStyle}
                                            submitText={staticPage.submitText}
                                            cancelStyle={parseStyleCss(staticPage.cancelStyle)}
                                            cancelText={staticPage.cancelText}

                                            allowDescription={false}
                                            maxNominations={form.maxNominations || MAX_NOMINATION_COUNT}
                                            submitterEmail={submitter?.email || user?.email}
                                            onSave={onSave}
                                            onCancel={submitModal.closeModal}
                                        />
                                    </div>
                                )}
                            </TabsView>
                        </ProtectedSubmitter>
                    </Modal>
                }
                {gratitudeModal.modal &&
                    <Modal title={staticPage.formTitle ? undefined : strings.dlg_title_submit_entity} disableDoubleClickClose
                        style={dialogStyle}
                        onClose={submitModal.closeModal}>
                        <GratitudeView
                            text={staticPage.gratitude || strings.msg_gratitude}
                            style={formStyle}
                            logo={staticPage.formLogo}
                            title={staticPage.formTitle}
                            closeStyle={submitStyle}
                            onClose={gratitudeModal.closeModal} />
                    </Modal>
                }
            </div>
        );
    } else {
        return <ErrorMessage message={error || strings.err_load_static_page} />
    }

}

interface ProtectedSubmitterProps {
    contestId: dbid
    buttonStyle?: React.CSSProperties
    children: React.ReactElement
}

export function ProtectedSubmitter({ contestId, buttonStyle, children }: ProtectedSubmitterProps) {
    const { submitter, user } = useTypedSelector(state => state.auth);
    const [email, setEmail] = useState<string>()
    const [error, setError] = useState<string>()
    const dispatch = useAppDispatch()
    if (submitter || user?.email)
        return children;
    if (!email) {
        const onStartSubmit: SubmitHandler<ISubmitterStartForm> = async (data) => {
            // dispatch(submitterLoginAction(contestId, data.username, data.password))
            try {
                const resp = await SubmitterService.start(contestId, data.email.trim())
                if (resp.statusText == "OK") {
                    setEmail(data.email);
                }
            } catch (e) {
                console.log(e);
                // error(dispatch, e)
                if (e instanceof AxiosError) {
                    setError(e.response?.data?.message || e.response?.statusText || strings.err_server)
                }
            }
        }

        return <>
            <SubmitterStart title=""
                buttonStyle={buttonStyle}
                onSubmit={onStartSubmit} />
            {error && <ErrorMessage className="mt-2" message={error} />}
        </>
    } else {
        const onLoginSubmit: SubmitHandler<ILoginForm> = async (data) => {
            dispatch(submitterLoginAction(contestId, data.username, data.password))
        }

        return <SubmitterLogin title="" email={email}
            buttonStyle={buttonStyle}
            onSubmit={onLoginSubmit} />
    }
}

interface ISubmitterStartForm {
    email: string
}

interface ISubmitterStrtProps {
    title: string
    buttonStyle?: React.CSSProperties
    onSubmit: SubmitHandler<ISubmitterStartForm>
}

export function SubmitterStart({ buttonStyle, onSubmit }: ISubmitterStrtProps) {
    const { register, formState: { errors }, handleSubmit } = useForm<ISubmitterStartForm>({ defaultValues: {} as ISubmitterStartForm })

    return (
        <form title="" className='flex flex-col space-y-2 mt-2 w-full'
            onSubmit={handleSubmit(onSubmit)}
        >
            <LabelWith text={strings.lab_email_to_submit} error={errors.email}>
                <input className='textinput'
                    placeholder={strings.lab_email}
                    {...register("email", emailValidationNotStrict(true))} />
            </LabelWith>

            <button type='submit' className='textbutton mt-4'
                style={buttonStyle}>
                {strings.button_confirm_email}
            </button>
        </form>
    )
}

interface ISubmitterLoginProps {
    title: string
    email: string
    buttonStyle?: React.CSSProperties
    onSubmit: SubmitHandler<ILoginForm>
}

export function SubmitterLogin({ title, email, buttonStyle, onSubmit }: ISubmitterLoginProps) {
    const { register, formState: { errors }, handleSubmit } = useForm<ILoginForm>({ defaultValues: { username: email } as ILoginForm })
    return (
        <form title={title} className='flex flex-col space-y-2 mt-2 w-full'
            onSubmit={handleSubmit(onSubmit)}>
            <LabelWith text={strings.lab_email_to_submit} error={errors.username}>
                <input className='textinput'
                    placeholder={strings.lab_email}
                    {...register("username")} disabled />
            </LabelWith>

            <span>{strings.msg_submitter_email_confirm}</span>

            <LabelWith text={strings.lab_pin} error={errors.password}>
                <input className='textinput' type="password"
                    placeholder={strings.lab_pin}
                    {...register("password", passwordValidation())} />
            </LabelWith>

            <button type='submit' className='textbutton'
                style={buttonStyle}>{strings.button_login}</button>
        </form>
    )
}

interface IGratitudeViewProps {
    style?: React.CSSProperties
    logo?: string
    title?: string
    text: string
    closeStyle?: React.CSSProperties
    closeText?: string
    onClose: () => void
}

function GratitudeView({ style, logo, title, text, closeStyle, closeText, onClose }: IGratitudeViewProps) {
    return (
        <div className="" style={style}>
            {logo &&
                <div className="flex flex-row justify-center">
                    <img className="max-w-[250px] max-h-[100px]" src={logo} />
                </div>
            }
            {title &&
                <div className="text-center text-[24px] pb-6">{title}</div>
            }

            <div className="mt-[40px] mb-[40px] flex flex-col justify-center">
                {text.split("\n").map(s =>
                    <div className="text-center text-[20px] min-h-[20px]">{s || " "}</div>
                )}
            </div>

            <button type='submit' className='textbutton'
                style={closeStyle} onClick={onClose}
            >{closeText || strings.button_close}</button>
        </div>
    )
}