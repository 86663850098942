import React, { useState } from "react"
import { UseItems } from "../hooks/useItems"
import strings from "../strings"
import { EmptyView, Loading } from "./Loading"
import { TextInput, TextInputType } from "./TextInput"
import { useDragReorder } from "../hooks/useDrag"
import { LogoView } from "./LogoView"

interface ListItemsReorderProps<T> {
    className?: string
    items: T[]
    render: (item: T, index: number) => React.ReactNode
    getKey: (item: T) => string
    onMore?: () => void
    onReorder: (items: T[]) => void
}

export function ListItemsReorder<T>({ items, render, className, getKey, onMore, onReorder }: ListItemsReorderProps<T>) {
    const dragging = useDragReorder(items, onReorder)
    function dragOverHandler(e: React.DragEvent<HTMLDivElement>, item: T) {
        e.preventDefault();
        dragging.onOver(item);
    }

    return (
        <div className={"items " + (className || "")}>
            {items.length == 0
                ? <EmptyView />
                : items.map((item, index) =>
                    <div className="items-drop"
                        onDragStart={() => dragging.onStart(item)}
                        onDragEnter={() => dragging.onEnter(item)}
                        onDragEnd={() => dragging.onEnd(item)}
                        onDragOver={e => dragOverHandler(e, item)}
                        draggable={true}
                        key={getKey(item)}
                    >{render(item, index)}</div>
                )}
            {onMore &&
                <div className="itemrow" onClick={onMore}>{strings.button_more}</div>
            }
        </div>
    )
}

interface ListItemsProps<T> {
    className?: string
    items: T[]

    emptyText?: string
    moreTitle?: string
    canMore?: boolean
    render: (item: T, index: number) => React.ReactNode
    getKey?: (item: T) => string
    onMore?: () => void
    onReorder?: (items: T[]) => void
}

export function ListItems<T>({ items, render, className, emptyText, moreTitle, canMore, getKey, onMore, onReorder }: ListItemsProps<T>) {
    return (
        <div className={"items " + (className || "")}>
            {items.length === 0
                ? <EmptyView text={emptyText} />
                : items.map((item, index) =>
                    render(item, index)
                )
            }
            {(canMore === undefined || canMore) && onMore &&
                <div className="itemrow text-center" onClick={onMore}>{moreTitle || strings.button_more}</div>
            }
        </div>
    )
}

interface UseListItemsProps<T, F> {
    className?: string
    useItems: UseItems<T>
    nobottom?: boolean
    search?: string
    filters?: F
    onFilter?: (items: T[], s: string, filters?: F) => T[]
    render: (item: T) => React.ReactNode
    renderTools?: () => React.ReactNode
    renderBottom?: () => React.ReactNode
}

export function ListUseItems<T, F>({ useItems, nobottom, className, search, filters, render, renderTools, renderBottom, onFilter }: UseListItemsProps<T, F>) {
    const [filter, setFilter] = useState("")
    const items = ((search || filter || filters) && onFilter) ? onFilter(useItems.items, search || filter, filters) : useItems.items
    return (
        <div className={"flex flex-col flex-auto " + (className || "")}>
            {(renderTools || onFilter) &&
                <div className="pb-2 flex flex-row flex-wrap gap-2 items-center">
                    {renderTools && renderTools()}

                    <div className="flex-auto"></div>

                    {(search === undefined && onFilter) &&
                        <TextInput className="min-w-[250px]"
                            type={TextInputType.text}
                            value={filter} setValue={setFilter}
                            label={null}
                            placeholder={strings.lab_search} />
                    }
                </div>
            }
            {/* {(filter )
            ?<div className="items">
                {filteredItems.map( item => render(item)) }
                { filteredItems.length === 0 &&
                <EmptyView/>
                }
            </div> */}
            {/* : */}
            <div className="items">
                {items.map(item => render(item))}
                {useItems.loading
                    ? <Loading />
                    : items.length === 0
                        ? <EmptyView />
                        : useItems.canMore
                            ? <div className="itemrow text-center" onClick={useItems.more}>{strings.button_more}</div>
                            : renderBottom ? renderBottom() : !nobottom && <div className="flex flex-row justify-center p-2">
                                <LogoView disabled size={30} />
                            </div>
                }
            </div>
            {/* } */}
        </div>
    )
}

export function FlowItems<T>({ items, render, className }: ListItemsProps<T>) {
    return (
        <div className={"flex flex-wrap " + (className || "") + (items.length === 0 ? "justify-center" : "")}>
            {items.length === 0
                ? <EmptyView />
                : items.map((item, index) => render(item, index))
            }
        </div>
    )
}

export function FlowUseItems<T, F>({ useItems, render, renderBottom, className }: UseListItemsProps<T, F>) {
    return (
        <div className="flex flex-col">
            <div className={"flex " + (className || "") + (useItems.items.length === 0 ? " flex-row justify-center items-center" : " flex-wrap")}>
                {useItems.items.map(item => render(item))}
                {useItems.loading
                    ? <Loading />
                    : useItems.items.length === 0 && <EmptyView />
                }
            </div>
            {useItems.canMore
                ? <div className="itemrow text-center" onClick={useItems.more}>{strings.button_more}</div>
                : renderBottom && renderBottom() //: <BottomRow/>
            }
        </div>
    )
}

function BottomRow() {
    return (
        <div className="flex flex-row justify-center p-2">
            <LogoView disabled size={30} />
        </div>
    )
}