import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { FieldError } from "react-hook-form";
import { useDragReorder } from "../../hooks/useDrag";
import { dbid, ITemplateField, PhotoSize } from "../../model/models";
import DataService from "../../services/DataService";
import strings from "../../strings";
import { useImageFullscreen } from "../forms/ImageFullscreen";
import { IconButton } from "../Icon";
import { ImageEx } from "../ImageFB";
import { ImgUploader } from "../ImgUploader";
import { LabelWith } from "../Label";
import { OnFieldDragImage } from "./FieldValue";
import { AxiosError } from "axios";

// type EditImagesValue = { images: string[], uploads: IUploadFile[] };

interface EditImagesFieldProps {
    className?: string
    labelClassName?: string
    labelStyle?: React.CSSProperties
    buttonStyle?: React.CSSProperties
    contestId: dbid
    entityId?: dbid
    // value?: string
    // value: EditImagesValue,//string[]
    images: string[]
    // uploads?: IUploadFile[]
    error?: FieldError
    field: ITemplateField
    onChange?: (items: string[]) => void
    // onChange?: (next: EditImagesValue) => void
    onDragImage?: OnFieldDragImage
    onBlur?: () => void
}

export function EditImagesField({ className, labelClassName, labelStyle, buttonStyle, images, error, field, contestId, entityId, onChange, onBlur, onDragImage }: EditImagesFieldProps) {

    const imgFullscreen = useImageFullscreen()
    const [uploading, setUploading] = useState(false)
    const [uploadError, setUploadError] = useState<FieldError>()
    const dragging = useDragReorder(images, updateValues)
    const onDrop = async (acceptedFiles: File[]) =>
        sendImagesFile(acceptedFiles)
    const { getRootProps, getInputProps, isDragActive, isDragAccept } = useDropzone({
        onDrop, accept: {
            "image/png": [], "image/jpeg": []
        },
    })
    const [uploadFiles, setUploadFiles] = useState<IUploadFile[]>([]);

    function updateValues(nextImages: string[]) {
        // onChange && onChange({...value, images: nextImages})
        onChange && onChange(nextImages)
    }

    async function sendImagesFile(files: File[]) {
        if (!onChange || files.length === 0)// || !uploads
            return
        setUploadError(undefined)
        setUploading(true)
        try {
            let nextImages = images
            let newImages = new Array<IUploadFile>();
            for (let file of files) {
                const resp = await DataService.uploadImage(file, contestId, entityId)
                if (resp.statusText === "OK") {
                    nextImages = [...nextImages, resp.data.orig]
                }
                // console.log(file.name);
                // const data = await loadDataFile(file);
                // if (data) {
                //     const uploadFile = { file, data: data.toString() } as IUploadFile;
                //     // uploads.push(uploadFile);
                //     newImages.push(uploadFile);
                // }
            }
            updateValues(nextImages)
            // setUploadFiles([...uploadFiles, ...newImages]);
        } catch (e) {
            if (e instanceof AxiosError) {
                console.error("AxiosError:" + e.message)
                setUploadError({ message: e.response?.data?.message || e.message } as FieldError)
            }
        } finally {
            setUploading(false)
        }
    }

    async function filesChangeHandler(files: FileList) {
        onBlur && onBlur()
        sendImagesFile(Array.from(files))
    }

    function deleteHandler(itemImg: string) {
        if (global.confirm(strings.confirm_delete_photo)) {
            const nextImages = images.filter(img => img !== itemImg)
            updateValues(nextImages)
        }
    }
    function deleteUploadHandler(file: IUploadFile) {
        if (global.confirm(strings.confirm_delete_photo)) {
            const nextUploadFiles = uploadFiles.filter(f => f != file);
            setUploadFiles(nextUploadFiles);
        }
    }
    // console.log("EditImagesFile recompose ", images)
    function onDragStart(e: React.DragEvent<HTMLImageElement>, img: string) {
        onDragImage && onDragImage(field.name, img, e, "start")
        onChange && dragging.onStart(img)
    }
    function onDragEnter(e: React.DragEvent<HTMLImageElement>, img: string) {
        onDragImage && onDragImage(field.name, img, e, "enter")
        onChange && dragging.onEnter(img)
    }
    function onDragEnd(e: React.DragEvent<HTMLImageElement>, img: string) {
        onDragImage && onDragImage(field.name, img, e, "end")
        onChange && dragging.onEnd(img)
    }

    return (
        <LabelWith text={`${field.label} (${images.length})`} error={uploadError || error}
            className={className} labelClassName={labelClassName}
            labelStyle={labelStyle}>

            <div {...(onChange ? getRootProps() : {})}
                className={`relative w-full min-h-[100px] flex flex-row flex-wrap gap-1 ${isDragActive ? (isDragAccept ? "bg-success" : "bg-error") : ""}`}>
                <div className="absolute-center text-center text-sm"
                    style={{ opacity: 0.5 }}>
                    {field.hint || strings.msg_empty_images}
                </div>
                {onChange &&
                    <input {...getInputProps()} />
                }
                {images.map(img =>
                    <div className="relative" key={img}>
                        <ImageEx key={img} className="w-24 h-24 rounded"
                            onDragStart={e => onDragStart(e, img)}
                            onDragEnter={e => onDragEnter(e, img)}
                            onDragEnd={e => onDragEnd(e, img)}
                            src={img}
                            size={PhotoSize.small}
                            onClick={e => { e.stopPropagation(); imgFullscreen.open(img); }} />
                        {onChange &&
                            <IconButton className="absolute right-1 top-1"
                                style={buttonStyle}
                                name="delete"
                                onClick={() => deleteHandler(img)} />
                        }
                    </div>
                )}
                {uploadFiles.map(file =>
                    <div className="relative" key={file.file.name}>
                        <ImageEx key={file.file.name} className="w-24 h-24 rounded"
                            onDragStart={e => onDragStart(e, file.data)}
                            onDragEnter={e => onDragEnter(e, file.data)}
                            onDragEnd={e => onDragEnd(e, file.data)}
                            src={file.data}
                            size={PhotoSize.small}
                            onClick={e => { e.stopPropagation(); imgFullscreen.open(file.data); }} />
                        {onChange &&
                            <IconButton className="absolute right-1 top-1"
                                style={buttonStyle}
                                name="delete"
                                onClick={() => deleteUploadHandler(file)} />
                        }
                    </div>
                )}
                {
                    // uploading
                    //     ? <div className="relative w-24 h-24 bg-white/5 select-none rounded flex flex-row justify-center items-center">
                    //         <InfiniteLoading className="" />
                    //     </div> : 
                    onChange && //uploads &&
                    <ImgUploader
                        className="relative w-24 h-24 bg-white/5 hover:bg-secondary select-none rounded"
                        buttonClassName="text-2xl absolute-center"
                        text="+"
                        multiple
                        onUpload={filesChangeHandler}
                    />
                }
            </div>
            {imgFullscreen.render()}
        </LabelWith>
    )
}

function loadDataFile(file: File) {
    let promise = new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = function () {
            const data = reader.result
            //     // console.log(res);
            //     if (data) {
            //         addUploadFile({ file, data: data.toString() } as IUploadFile);
            //     }
            if (data) {
                resolve(data.toString())
            } else {
                reject()
            }
        }
    });
    return promise;
}

export interface IUploadFile {
    file: File
    data: string
}

export function UploadPreview() {

    const [images, setImages] = useState<IUploadFile[]>([]);

    const onDrop = async (acceptedFiles: File[]) => {
        // console.log("onDrop", acceptedFiles);
        acceptedFiles.forEach(file => {
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function () {
                const res = reader.result
                // console.log(res);
                if (res) {
                    setImages([...images, { file, data: res.toString() }])
                }
                // let img = document.createElement('img')
                // img.src = reader.result
                // document.getElementById('gallery').appendChild(img)
            }
        });
    }
    // sendImagesFile(acceptedFiles)

    const { getRootProps, getInputProps, isDragActive, isDragAccept } = useDropzone({
        onDrop, accept: {
            "image/png": [], "image/jpeg": []
        },
    })

    return (
        <LabelWith text={`UploadPreview ${images.length}`}>
            <div className={`flex flex-row flex-wrap min-w-[200px] min-h-[200px] ${isDragActive ? (isDragAccept ? "bg-success" : "bg-error") : "bg-black"}`} {...getRootProps()} >
                {images.map(file =>
                    <img key={file.file.name} src={file.data} className="w-[100px] h-[100px]" />
                )}

            </div>
        </LabelWith>
    );
}