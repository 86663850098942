import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Checkbox } from "../comp/Checkbox";
import { CreateContestEntity } from "../comp/forms/CreateContestEntity";
import { EditNominationsContestEntityWithLoad } from "../comp/forms/EditNominationsContestEntity";
import { IconButton } from "../comp/Icon";
import { LabelWith } from "../comp/Label";
import { ListItems, ListItemsReorder } from "../comp/ListItems";
import { Modal } from "../comp/Modal";
import NominationRow from "../comp/rows/NominationRow";
import { TabsView } from "../comp/TabsView";
import { TextField } from "../comp/TextField";
import { parseStyleCss } from "../extends/css";
import { useContest, useStaticPage } from "../hooks/useItems";
import { useModal } from "../hooks/useModal";
import { useTitle } from "../hooks/useTitle";
import { dbid, IContest, IContestEntity, INomination, IStaticForm, IStaticPage, ITemplateField, MAX_NOMINATION_COUNT } from "../model/models";
import StaticPageService from "../services/StaticPageService";
import strings from "../strings";

export function StaticPageEditorPage() {
    const contestId = useParams().contestId || "0";
    const pageId = useParams().id || "0";
    useTitle(strings.lab_static_page);

    const { staticPage, setStaticPage } = useStaticPage(pageId, false);
    const { contest } = useContest(contestId);
    const [registrationStart, setRegistrationStart] = useState<string>()
    const [registrationFinish, setRegistrationFinish] = useState<string>()
    useEffect(() => {
        setRegistrationStart(
            staticPage?.registrationStart ? new Date(staticPage.registrationStart).toLocaleString() : ""
        )
        setRegistrationFinish(
            staticPage?.registrationFinish ? new Date(staticPage.registrationFinish).toLocaleString() : ""
        )
    }, [staticPage?.id])

    function saveStaticPage() {
        if (!staticPage) {
            return;
        }
        StaticPageService.update(staticPage);
    }
    function setPageUriHandler() {
        if (!staticPage)
            return;
        var nextUri = prompt("Изменить URL страницы", staticPage.uri || staticPage.id)
        if (nextUri) {
            setStaticPage({ ...staticPage, uri: nextUri });
        }
    }
    function addFormHandler() {
        if (!staticPage || !contest) {
            return;
        }
        const s = prompt("Название формы регистрации");
        if (s == null) {
            return;
        }
        var forms = [...staticPage.forms || [], { title: s, fields: contest.template?.fields || [], nominations: [] } as IStaticForm]
        setStaticPage({ ...staticPage, forms: forms });
    }

    return (
        <>
            <div className='maincontainer flex flex-col '>
                <span className="debugfield">ContestID: #{contestId}, PageID: #{pageId}</span>
                {staticPage &&
                    <>
                        <div className="flex flex-row justify-end gap-x-2">
                            <Checkbox text="Опубликовано"
                                value={staticPage.isPublic}
                                setValue={e => setStaticPage({ ...staticPage, isPublic: e })}
                                left />
                            <button className="greenbutton" onClick={saveStaticPage}>{strings.button_save}</button>
                            <button className="textbutton" disabled>{strings.mi_remove}</button>
                        </div>
                        <LabelWith text={strings.lab_static_page_title}>
                            <input className="textinput"
                                value={staticPage.title} onChange={e =>
                                    setStaticPage({ ...staticPage, title: e.target.value })}></input>
                        </LabelWith>
                        <LabelWith text={strings.lab_static_page_desc}>
                            <textarea className="textinput" rows={3}
                                placeholder={strings.lab_static_page_desc}
                                value={staticPage.description} onChange={e =>
                                    setStaticPage({ ...staticPage, description: e.target.value })}></textarea>
                        </LabelWith>
                        <LabelWith text="Дата начала регистрации на конкурс">
                            <>
                                <span>Пример: 02 Dec 2024 07:00:00 GMT+0500</span>
                                <pre>{staticPage.registrationStart || "-"}</pre>
                                <input className="textfield"
                                    value={registrationStart || ""}
                                    onChange={e => {
                                        setRegistrationStart(e.target.value);
                                        setStaticPage({ ...staticPage, registrationStart: Date.parse(e.target.value) || undefined })
                                    }} />
                            </>
                        </LabelWith>
                        <LabelWith text="Дата окончания регистрации на конкурс">
                            <>
                                <span>Пример: 23 Dec 2024 21:00:00 GMT+0500</span>
                                <pre>{staticPage.registrationFinish || "-"}</pre>
                                <input className="textfield"
                                    value={registrationFinish || ""}
                                    onChange={e => {
                                        setRegistrationFinish(e.target.value)
                                        setStaticPage({ ...staticPage, registrationFinish: Date.parse(e.target.value) || undefined });
                                    }} />
                            </>
                        </LabelWith>
                        <TextField className="flex-auto"
                            label={strings.lab_static_page_uri}
                            text={document.location.protocol + "//" + document.location.host + "/p/" + staticPage.uri || staticPage.id}
                            emptyPlaceholder={strings.msg_missing}
                            buttonIcon="edit"
                            buttonOnClick={setPageUriHandler} />

                        <LabelWith text="Стили диалога регистрации">
                            <input className="textinput"
                                value={staticPage?.dialogStyle || ""}
                                onChange={e => {
                                    staticPage.dialogStyle = e.target.value;
                                    setStaticPage({ ...staticPage });
                                }}></input>
                        </LabelWith>
                        <LabelWith text="Стили формы регистрации">
                            <input className="textinput"
                                value={staticPage?.formStyle || ""}
                                onChange={e => {
                                    staticPage.formStyle = e.target.value;
                                    setStaticPage({ ...staticPage });
                                }}></input>
                        </LabelWith>

                        <LabelWith text="Лого формы регистрации">
                            <input className="textinput"
                                value={staticPage?.formLogo || ""}
                                onChange={e => {
                                    staticPage.formLogo = e.target.value;
                                    setStaticPage({ ...staticPage });
                                }}></input>
                        </LabelWith>
                        <LabelWith text="Заголовок формы регистрации">
                            <input className="textinput"
                                value={staticPage?.formTitle || ""}
                                onChange={e => {
                                    staticPage.formTitle = e.target.value;
                                    setStaticPage({ ...staticPage });
                                }}></input>
                        </LabelWith>
                        <LabelWith text="Стиль кнопки отправить">
                            <input className="textinput"
                                value={staticPage?.submitStyle || ""}
                                onChange={e => {
                                    staticPage.submitStyle = e.target.value;
                                    setStaticPage({ ...staticPage });
                                }}></input>
                        </LabelWith>
                        <LabelWith text="Текст кнопки отправить">
                            <input className="textinput"
                                value={staticPage?.submitText || ""}
                                onChange={e => {
                                    staticPage.submitText = e.target.value;
                                    setStaticPage({ ...staticPage });
                                }}></input>
                        </LabelWith>
                        <LabelWith text="Стиль кнопки отмена">
                            <input className="textinput"
                                value={staticPage?.cancelStyle || ""}
                                onChange={e => {
                                    staticPage.cancelStyle = e.target.value;
                                    setStaticPage({ ...staticPage });
                                }}></input>
                        </LabelWith>
                        <LabelWith text="Текст кнопки отмена">
                            <input className="textinput"
                                value={staticPage?.cancelText || ""}
                                onChange={e => {
                                    staticPage.cancelText = e.target.value;
                                    setStaticPage({ ...staticPage });
                                }}></input>
                        </LabelWith>
                        <LabelWith text="Стили полей">
                            <input className="textfield"
                                value={staticPage.fieldsClassName || ""}
                                onChange={e => {
                                    setStaticPage({ ...staticPage, fieldsClassName: e.target.value })
                                }}></input>
                        </LabelWith>
                        <LabelWith text="Стили подписей">
                            <input className="textfield"
                                value={staticPage.fieldsLabelStyle || ""}
                                onChange={e => {
                                    setStaticPage({ ...staticPage, fieldsLabelStyle: e.target.value })
                                }}></input>
                        </LabelWith>

                        {/* <Label text="Предпросмотр" /> */}
                        <div className="flex flex-row">
                            <button className="textbutton mt-4" onClick={addFormHandler}>Добавить форму регистрации</button>
                        </div>
                    </>
                }
            </div>

            {staticPage && contest &&
                <TabsView className="w-full">
                    {staticPage.forms?.map((e, n) =>
                        <StaticForm title={e.title} key={n}
                            form={e} contest={contest}
                            staticPage={staticPage} setStaticPage={setStaticPage} />
                    )}
                </TabsView>
            }
        </>
    );
}

interface IStaticFormProps {
    // key: React.Key | null | undefined
    title: string
    contest: IContest
    staticPage: IStaticPage
    setStaticPage: (next: IStaticPage) => void
    form: IStaticForm
}

function StaticForm({ title, contest, staticPage, setStaticPage, form }: IStaticFormProps) {
    // const allNominations = useNominationsAll(contest.id)
    useEffect(() => {
        if (!form.maxNominations && contest.maxNominations) {
            form.maxNominations = contest.maxNominations
        }
        if (form.fields && contest.template?.fields) {
            form.fields = form.fields.map(e =>
                contest.template?.fields?.find(e2 => e2.name == e.name) || e
            )
        } else {
            form.fields = contest.template?.fields || [];
        }
    }, [contest.id])
    const nominateModal = useModal<boolean>()

    function setFormFields(next: ITemplateField[]) {
        form.fields = next;
        setStaticPage({ ...staticPage })
    }
    function setFormName() {
        var next = prompt("Реадактировать название формы", form.title);
        if (next == null) {
            return;
        }
        form.title = next;
        setStaticPage({ ...staticPage })
    }
    function restoreFields() {
        form.fields = contest.template?.fields || [];
        setStaticPage({ ...staticPage })
    }
    function removeField(field: ITemplateField) {
        if (!global.confirm(strings.confirm_delete_template_field)) {
            return
        }
        form.fields = form.fields.filter(e => e != field);
        setStaticPage({ ...staticPage })
    }
    function removeNomination(nomination: INomination) {
        if (!global.confirm(strings.confirm_delete_nomination)) {
            return
        }
        form.nominations = (form.nominations || []).filter(e => e.id != nomination.id);
        setStaticPage({ ...staticPage })
    }
    function saveNominations(ids: dbid[], next: INomination[]) {
        form.nominations = next;
        setStaticPage({ ...staticPage })
        nominateModal.closeModal()
    }
    function setMaxNominations(e: React.ChangeEvent<HTMLSelectElement>) {
        const n = Number.parseInt(e.target.value)
        if (!n || n < 1) {
            return
        }
        form.maxNominations = n;
        setStaticPage({ ...staticPage })
    }
    const nominations = form.nominations || []
    return (
        <div className="flex flex-col" title={form.title}>
            <div className='maincontainer flex flex-col '>
                <TextField className="flex-auto"
                    label="Название формы"
                    text={form.title}
                    emptyPlaceholder={strings.msg_missing}
                    buttonIcon="edit"
                    buttonOnClick={setFormName} />
                <LabelWith text={strings.lab_max_nominations}>
                    <select className="textfield"
                        value={form.maxNominations || contest.maxNominations || MAX_NOMINATION_COUNT}
                        onChange={setMaxNominations}
                    >
                        {!contest.maxNominations &&
                            <option value={MAX_NOMINATION_COUNT} key={0}>{strings.msg_default_max_nominations}</option>
                        }
                        {Array.from(Array(10).keys()).map(n =>
                            <option value={n + 1} key={n + 1}>{n + 1}</option>
                        )}
                    </select>
                </LabelWith>

                <LabelWith text="Подпись для поля ''Название объекта''">
                    <input className="textinput"
                        value={form?.titleLabel || ""}
                        onChange={e => {
                            form.titleLabel = e.target.value;
                            setStaticPage({ ...staticPage });
                        }}></input>
                </LabelWith>
                <LabelWith text="Хинт для поля ''Название объекта''">
                    <input className="textinput"
                        value={form?.titleHint || ""}
                        onChange={e => {
                            form.titleHint = e.target.value;
                            setStaticPage({ ...staticPage });
                        }}></input>
                </LabelWith>

                <TabsView>
                    <div title="Поля регистрации">
                        <button className="textbutton mt-2" onClick={restoreFields}>Восстановаить полный список полей</button>
                        <LabelWith text="Порядок полей регистрации">
                            <ListItemsReorder items={form.fields}
                                onReorder={setFormFields}
                                getKey={item => item.name}
                                render={item =>
                                    <div className="flex flex-row itemrow">
                                        <span key={item.name} className="flex-auto">{item.label}</span>
                                        <IconButton name="delete" onClick={() => removeField(item)} />
                                    </div>
                                } />
                        </LabelWith>
                    </div>
                    <div title={`Номинации ${nominations.length}`} className="flex flex-col">
                        <div className="flex flex-row mt-4">
                            <button className="textbutton" onClick={() => nominateModal.openModal(true)}>{strings.button_add}</button>
                        </div>
                        <LabelWith text={strings.lab_nominations}>
                            <ListItems items={nominations}
                                render={item =>
                                    <NominationRow item={item} onDelete={() => removeNomination(item)} />
                                } />
                        </LabelWith>
                        {nominateModal.modal &&
                            <Modal title={strings.dlg_title_edit_entity_nominations} onClose={nominateModal.closeModal} >
                                <EditNominationsContestEntityWithLoad
                                    contestId={contest.id}
                                    nominationIds={nominations?.map(n => n.id || "0") || []}
                                    maxNominations={form.maxNominations || MAX_NOMINATION_COUNT}
                                    onSave={saveNominations}
                                    onCancel={nominateModal.closeModal}
                                />
                            </Modal>
                        }
                    </div>
                </TabsView>
            </div>
            <div className="w-full bg-black/50 left-0 top-0 right-0 bottom-0 flex justify-center overflow-auto z0-10">
                <div className={'absolute0 flex flex-col p-4 rounded bg-primary m-5 w-350px'}
                    style={
                        parseStyleCss(staticPage.dialogStyle)
                    }
                    onClick={e => e.stopPropagation()}
                    onDoubleClick={e => e.stopPropagation()}>

                    <CreateContestEntity contestId={staticPage.contestId}
                        fields={form.fields}
                        nominations={form.nominations || []}
                        item={{} as IContestEntity}

                        style={parseStyleCss(staticPage.formStyle)}
                        labelStyle={parseStyleCss(staticPage.fieldsLabelStyle)}
                        fieldClassName={staticPage.fieldsClassName}
                        logo={staticPage.formLogo}
                        title={staticPage.formTitle}
                        titleLabel={form.titleLabel}
                        titleHint={form.titleHint}
                        submitStyle={parseStyleCss(staticPage.submitStyle)}
                        submitText={staticPage.submitText}
                        cancelStyle={parseStyleCss(staticPage.cancelStyle)}
                        cancelText={staticPage.cancelText}

                        maxNominations={contest.maxNominations || MAX_NOMINATION_COUNT}
                        allowDescription={false}
                        submitterEmail="noreply@mail.com"
                        onSave={() => { }} onCancel={() => { }}
                    />
                </div>
            </div>
        </div>
    )
}