import api from "../api";
import { UseItems, useItems } from "../hooks/useItems";
import { IContest, IUser } from "../model/models";

export interface AdminStats {
    size: number
    freeSize: number
    totalSize: number
}

export interface IAdminService {
    stats(): Promise<AdminStats>

    // useSiteUsers(): UseItems<IUser>
    // useSiteContests(): UseItems<IContest>
}

class AdminService implements IAdminService {

    constructor() {
        // console.log("AdminService init")
    }

    async stats(): Promise<AdminStats> {
        const resp = await api.get<AdminStats>(`/admin/stats`)
        return resp.data
    }

    // useSiteUsers() {
    //     return useItems<IUser>(`/admin/users`)
    // }
    // useSiteContests() {
    //     return useItems<IContest>(`/admin/contests`)
    // }
}

const service: IAdminService = new AdminService()
export default service
// export default class AdminService {
//     static async stats() {
//         return await api.get<AdminStats>(`/admin/stats`)
//     }
// }