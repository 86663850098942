import { useState } from "react";
import { EmptyView, Loading, LoadingProgress } from "../comp/Loading";
import { TextField } from "../comp/TextField";
import { TextInput, TextInputType } from "../comp/TextInput";
import strings from "../strings";
import { Checkbox } from "../comp/Checkbox";
import { Combobox } from "../comp/Combobox";
import { DescrView } from "../comp/DescrView";
import { ErrorMessage } from "../comp/ErrorMessage";
import { ImgUploader } from "../comp/ImgUploader";
import { LogoView } from "../comp/LogoView";
import ContestRow from "../comp/rows/ContestRow";
import { Label } from "../comp/Label";
import { EntityState, IContest, IContestEntity, IContestUser, INomination } from "../model/models";
import ContestEntityRow from "../comp/rows/ContestEntityRow";
import NominationRow from "../comp/rows/NominationRow";
import { ContestUserRow } from "../comp/rows/ContestUserRow";
import { useTitle } from "../hooks/useTitle";
import { RichEdit } from "../comp/RichEdit";
import { Icon } from "../comp/Icon";
import { VotingPoints } from "../comp/VotingPoints";

const exampleContest = {
    title: "Пример конкурса 2021 года", 
    description: "Описание примера конкурса 2021 года\nLorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur maiores, eos sed modi esse sint? A porro necessitatibus voluptas aspernatur voluptatibus sint! Labore ipsam atque hic quod vero? Molestiae, quisquam!", 
    entities: 0, nominations: 0, isPublished: true
} as IContest

const exampleNomination = {
    title: "25. Лучшее новогоднее оформление предприятия торговли",
    description: "Лучшее новогоднее оформление предприятия торговли"
} as INomination

const exampleEntity = {
    title: "Склад ООО \"ГазТраст\" на Щорса 26",
    description: "Склад ООО \"ГазТраст\" на Щорса 26",
    canDelete: true,
    canEdit: true,
    isAdmin: true,
    state: EntityState.CORRECTION_REQUIRED.toString()
} as IContestEntity

const exampleContestUser = {
    contestId: "",
    id: "",
    name: "User 1",
    isAdmin: true,
    avatar: undefined
} as IContestUser

export function DebugPage() {
    useTitle("Debug - Отладка")
    const [value, setValue] = useState("")
    const [points, setPoints] = useState(5)
    const [checked, setChecked] = useState(false)
    return (
        <div className="maincontainer flex flex-col justify-center w-full pb-2">
            <div className="header pb-big">Отладка</div>
            <VotingPoints value={points} onChange={setPoints}/>
            
            <RichEdit/>
            <EmptyView/>    
            <Loading/>
            <LoadingProgress progress={50} max={100}/>
            <ErrorMessage message={strings.err_auth}/>
            <TextField label={strings.lab_title} text="Text"/>
            <TextInput placeholder={strings.lab_title} type={TextInputType.text} 
                value={value} setValue={setValue}/>
            <DescrView body="Пример <b>текста</b> html"/>
            {/* <Combobox value={value} setValue={setValue} label={strings.lab_actions}
                items={[ { text: "Вариант 1" },{ text: "Вариант 2" } ]}/> */}
            <Checkbox value={checked} setValue={setChecked} 
                text={strings.msg_required} />
            <Checkbox value={checked} setValue={setChecked} 
                text={strings.msg_required} left/>
            <ImgUploader onUpload={() => console.log("ImgUploader")}/>
            <LogoView/>
            {/* <AvatarView /> */}
            <Label text="Contest row"/>
            <ContestRow item={exampleContest}/>
            <Label text="Nomination row"/>
            <NominationRow item={exampleNomination}/>
            <Label text="ContestEntity row"/>
            <ContestEntityRow item={exampleEntity} contestId=""/>
            <Label text="ContestUser row"/>
            <div className="flex flex-wrap">
                <ContestUserRow user={exampleContestUser}/>
            </div>

            {/* <a href="/docs/template/64f811cff35569664f3129e3">Excel template editor</a> */}
            {/* <a href="/pptx/template/64f811cff35569664f3129e3">PowerPoint template editor</a> */}
        </div>
    )
}