interface IVotingPointsProps {
    className?: string
    textClassName?: string
    value: number
    onChange?: (next: number) => void
}

export function VotingPoints({ className, textClassName, value, onChange }: IVotingPointsProps) {
    return (
        <div className={"flex flex-row items-center select-none " + (className || "")}>
            <StarView value={value} index={0} onChange={onChange}/>
            <StarView value={value} index={1} onChange={onChange}/>
            <StarView value={value} index={2} onChange={onChange}/>
            <StarView value={value} index={3} onChange={onChange}/>
            <StarView value={value} index={4} onChange={onChange}/>
            <div className={ textClassName != null ? textClassName : "ml-4 text-lg"}>{value}</div>
        </div>
    );
}

interface IStarViewProps {
    value: number
    index?: number
    onChange?: (next: number) => void
}

function StarView({ value, index, onChange }: IStarViewProps) {
    const state = pointsValueToState(value, index || 0);
    function clickHandler(e: React.MouseEvent<HTMLImageElement, MouseEvent>) {
        if (!onChange) {
            return;
        }
        const x = e.pageX - e.currentTarget.offsetLeft;
        const p = x / e.currentTarget.width

        var addPoints;
        if (p > 0.6) {
            addPoints = 2;//StarState.FIL);
        } else if (p > 0.2) {
            addPoints = 1;//StarState.HALF);
        } else {
            addPoints = 0;//StarState.NONE);
        }
        onChange((index || 0)*2 + addPoints);        
    }
    return <img src={`/svg/star${state}.svg`} onClick={clickHandler} className="border0 w-[40px]" draggable={false}/>
}

enum StarState {
    NONE = '0', HALF = '2', FIL = '1'
}

function pointsValueToState(points: number, index: number): StarState {
    const m = points - 2*index;
    if (m > 1) {
        return StarState.FIL // fill star
    } else if (m > 0) {
        return StarState.HALF // half star
    } else {
        return StarState.NONE // empty star
    }
}
