import React, { ReactElement, useState } from "react"
import { ITab } from "./TabsView"
import { LabelWith } from "./Label"

const defaultTabClass = "cursor-default select-none py-2 pr-4"//border-0 w-full text-center0 cursor-default select-none"
const defaultActiveClass = "font-bold"//border0-b-4 font-bold"
const defaultInactiveClass = ""//border0-b-2"

interface SectionsViewProps {
    initIndex?: number
    className?: string
    tabClass?: string
    activeClass?: string
    inactiveClass?: string
    pagesClass?: string
    onChange?: (index: number) => void
    children: React.ReactNode
}

export function SectionsView(props: SectionsViewProps) {
    const tabClass = props.tabClass || defaultTabClass
    const activeClass = props.activeClass || defaultActiveClass
    const inactiveClass = props.inactiveClass || defaultInactiveClass

    let tabs: ITab[] = []
    React.Children.forEach(props.children, (child, index) => {
        let el = child as ReactElement
        if (el) {
            let title = el.props.title || `Tab ${index + 1}`
            let tabKey = title//el.props.key || 
            tabs.push({ title: title, tabKey: tabKey, index: tabs.length, child: el })
        }
    })
    const startIndex = (props.initIndex && props.initIndex < tabs.length && props.initIndex) || 0
    const [index, setIndex] = useState(startIndex)

    function onTabClickHandler(index: number) {
        setIndex(index)
        props.onChange && props.onChange(index)
    }

    const currentIndex = index < tabs.length ? index : startIndex
    return (
        <div className={'w-full flex flex-col md:flex-row ' + (props.className || "")}>
            <LabelWith className="md:hidden" text="Разделы">
                <select className="textfield"
                    value={currentIndex} onChange={e => onTabClickHandler(Number.parseInt(e.target.value) || 0)}>
                    {tabs.map(tab =>
                        <option key={tab.tabKey} value={tab.index}
                            className={`${tabClass} ${tab.index === currentIndex ? activeClass : inactiveClass}`}

                        >{tab.title}</option>
                    )}
                </select>
            </LabelWith>
            <div className='hidden md:block'>
                {tabs.map(tab =>
                    <div key={tab.tabKey}
                        className={`${tabClass} ${tab.index === currentIndex ? activeClass : inactiveClass}`}
                        onClick={() => onTabClickHandler(tab.index)}
                    >{tab.title}</div>
                )}
            </div>

            <div className={"flex-auto pt-4 md:pt-0 " + (props.pagesClass || "")}>
                {tabs[currentIndex]?.child}
            </div>
        </div>
    )
}