import { useEffect, useState } from "react"
import AdminService, { AdminStats } from "../services/AdminService"
import { ListUseItems } from "../comp/ListItems"
import { ContestUserRow } from "../comp/rows/ContestUserRow"
import strings from "../strings"
import { LabelWith } from "../comp/Label"
import ContestRow from "../comp/rows/ContestRow"
import { useSiteContests, useSiteUsers } from "../hooks/useItems"

const KB = 1024
const MB = 1024 * KB
const GB = 1024 * MB
const TB = 1024 * GB

function getSizeString(size: number) {
    if (size >= TB)
        return (size / TB).toFixed(2) + "Tb"
    else if (size >= GB)
        return (size / GB).toFixed(2) + "Gb"
    else if (size >= MB)
        return (size / MB).toFixed(2) + "Mb"
    else if (size >= KB)
        return (size / KB).toFixed(2) + "Kb"
    return size + "b"
}

export function AdminPanelPage() {
    const [stats, setStats] = useState<AdminStats>()
    const users = useSiteUsers()
    const contests = useSiteContests()
    async function fetch() {
        try {
            const stats = await AdminService.stats()
            setStats(stats)
        } catch (e) {
            console.error(e)
        } finally {

        }
    }
    useEffect(() => {
        fetch()
    }, [])
    return (
        <div className="flex flex-col maincontainer">
            <h1 className="text-right text-4xl font-thin">{strings.nav_admin}</h1>
            {stats &&
                <div className="text-xl font-thin flex flex-col">
                    <span>Всего объем диска: <b>{getSizeString(stats.totalSize)}</b>, свободно <b>{getSizeString(stats.freeSize)}</b></span>
                    <span>Занятный сайтом объем: <b>{getSizeString(stats.size)}</b></span>
                </div>
            }
            <div className="flex flex-row gap-4">
                <div className="flex flex-col w-1/2">
                    <LabelWith text={strings.lab_contests}>
                        <ListUseItems useItems={contests}
                            render={item =>
                                <ContestRow key={item.id} item={item} />
                            } />
                    </LabelWith>
                </div>
                <div className="flex flex-col w-1/2">
                    <LabelWith text={strings.lab_users}>
                        <ListUseItems useItems={users}
                            render={item =>
                                <ContestUserRow key={item.id} user={item} className="w-full"
                                    showDetails />
                            } />
                    </LabelWith>
                </div>
            </div>
        </div>
    )
}