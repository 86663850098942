import api from "../api"
import { IContestUser, dbid } from "../model/models"
import { IDataQuery } from "../model/query"
import { AuthResponse } from "../model/response"

function encode(s: string) {
    return btoa(encodeURIComponent(s))
}

function decode(base64: string) {
    try {
        return decodeURIComponent(atob(base64))
    } catch (error) {
        console.error(error)
    }
}

export interface IJuryData {
    contestId: dbid
    contestName: string
    username: string
    fullname: string
}

export default class JuryService {

    static async login(contestId: dbid, username: string, password: string) {
        return api.post<AuthResponse>("/jury/login", { contestId, username, password })
    }

    static async check() {
        return api.get<AuthResponse>("/jury")
    }

    static async createJuryContest(contestId: dbid,
        fullname: string, description: string, password: string,
        stage: dbid | undefined, stages: dbid[],
        presentationId: dbid | undefined, query: IDataQuery | undefined
    ) {
        const data = {
            fullname, description, password, stage: stage || undefined, stages,
            presentationId: presentationId ? presentationId : undefined,
            query
        }
        return await api.post<IContestUser>(`/contests/${contestId}/jury`, data)
    }

    static async updateJuryContest(juryId: dbid, contestId: dbid,
        fullname: string, description: string, password: string,
        stage: dbid | undefined, stages: dbid[],
        presentationId: dbid | undefined, query: IDataQuery | undefined
    ) {
        const data = {
            fullname, description, password, stage: stage || undefined, stages,
            presentationId: presentationId ? presentationId : undefined,
            query
        }
        return await api.post<IContestUser>(`/contests/${contestId}/jury/${juryId}`, data)
    }

    static async getJuryPassword(contestId: dbid, juryId: dbid) {
        return await api.get<string>(`/contests/${contestId}/jury/${juryId}/password`)
    }

    static async deleteJuryContest(contestId: dbid, juryId: dbid) {
        return await api.delete(`/contests/${contestId}/jury/${juryId}`)
    }

    static async getList(contestId: dbid, stageId: dbid | undefined, offset: number, limit: number) {
        return api.get<IContestUser[]>(`/contests/${contestId}/jury?offset=${offset}&limit=${limit}${stageId ? "&stageId=" + stageId : ""}`)
    }

    static decodeObject(base64: string): IJuryData | undefined {
        try {
            const s = decode(base64)
            // console.log(s)
            return s ? JSON.parse(s) as IJuryData : undefined
        } catch (error) {
            console.error(error)
        }
    }

    static encodeObject(data: IJuryData): string {
        return encode(JSON.stringify(data))
    }
}