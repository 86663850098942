import React from 'react';
import { IconButton } from './Icon';

interface ModalProps {
    title?: string
    className?: string
    style?: React.CSSProperties
    buttonStyle?: React.CSSProperties
    fullscreen?: boolean
    disableDoubleClickClose?: boolean
    wide?: boolean
    onClose: () => void
    children?: React.ReactNode
}

export function Modal(props: ModalProps) {
    return (
        <div className="fixed bg-black/50 left-0 top-0 right-0 bottom-0 flex justify-center overflow-auto z-10"
            onDoubleClick={props.disableDoubleClickClose ? undefined : props.onClose}
        >
            <div className={'absolute p-4 rounded bg-primary flex flex-col m-5 ' + (props.fullscreen ? "" : props.wide ? "md:w-[700px] w-[350px]" : "w-[350px] md:w-[400px]")}
                style={props.style}
                onClick={e => e.stopPropagation()}
                onDoubleClick={e => e.stopPropagation()}>
                <div className='flex flex-row justify-end items-center'>
                    {props.title &&
                        <h1 className='text-center pb-2 text-lg flex-auto'>{props.title}</h1>
                    }
                    <IconButton name='close'
                        style={props.buttonStyle}
                        onClick={props.onClose} />
                </div>
                {props.children}
            </div>
        </div>
    );
}