import { ITemplateField, TemplateFieldType } from "../../model/models";
import { ComboboxOption } from "../Combobox";
import strings from "../../strings";
import { SubmitHandler, useForm } from "react-hook-form";
import { LabelWith } from "../Label";
import { fieldNameValidation } from "./validations";
import ContextMenuButton from "../ContextMenuButton";
import { IContextMenuItem } from "../ContextMenu";

interface ITemplateFieldForm {
    required: boolean
    deprecated?: boolean
    type?: TemplateFieldType
    name: string
    label: string
    regex?: string
    enums?: string
    hint?: string
}
function toTemplateFieldForm(field: ITemplateField) {
    return {...field, enums: field?.enums?.join("\n")
    } as ITemplateFieldForm
}
function fromTemplateFieldForm(field: ITemplateFieldForm) {
    return {...field, enums: field?.enums?.split("\n")?.map( s => s.trim())?.filter( s => s.length > 0)
    } as ITemplateField
}

const TYPES = [
    { value: TemplateFieldType.TEXT, text: "Текст" }, 
    { value: TemplateFieldType.ENUM, text: "Перечисление" }, 
    { value: TemplateFieldType.BOOL, text: "Булевое (Да/Нет)" }, 
    { value: TemplateFieldType.IMAGE, text: "Картинка" }, 
    { value: TemplateFieldType.IMAGES, text: "Галерея картинок" }, 
    { value: TemplateFieldType.FILE, text: "Файл" }, 
    { value: TemplateFieldType.FILES, text: "Список файлов" }, 
    // { value: TemplateFieldType.VIDEO, text: "Видео" }
] as ComboboxOption[]

const PREFIELDS = [
    { label: "Адрес", name: "address", type: TemplateFieldType.TEXT } as ITemplateField,
    { label: "Фотографии", name: "photo", type: TemplateFieldType.IMAGES } as ITemplateField,
    { label: "Картинка", name: "image", type: TemplateFieldType.IMAGE } as ITemplateField,
    { label: "Телефон", name: "phone", type: TemplateFieldType.TEXT } as ITemplateField,
    { label: "Электронная почта", name: "email", type: TemplateFieldType.TEXT } as ITemplateField,
    { label: "Файл заявка на участие в конкурсе", name: "application_file", type: TemplateFieldType.FILE } as ITemplateField,
    { label: "Файл согласия на обработку персональных данных", name: "consent_file", type: TemplateFieldType.FILE } as ITemplateField,
]
const PREFIELDS_MENU = PREFIELDS.map(m => 
    { return { id: m.name, title: m.label } as IContextMenuItem }
)

interface CreateTemplateFieldProps {
    item?: ITemplateField
    onSave: (item: ITemplateField) => void
    onCancel?: () => void
}

export function CreateTemplateField({ item, onSave, onCancel }: CreateTemplateFieldProps) {
    const { 
        register, handleSubmit, 
        formState: { errors },
        watch, setValue
    } = useForm<ITemplateFieldForm>({ defaultValues: item && toTemplateFieldForm(item) })
    const onSubmit: SubmitHandler<ITemplateFieldForm> = async (data) => {
        // data.index = item?.index
        // data.enums = data.enums?.toString()?.split("\n")?.map( s => s.trim())?.filter( s => s.length > 0)
        // console.log(data)
        let next = fromTemplateFieldForm(data)
        next.index = item?.index
        if (next.deprecated) {
            next.required = false
        }
        onSave(next)
    }
    function prefieldsClickHandler(mi: IContextMenuItem) {
        const item = PREFIELDS.find(m => m.name === mi.id)
        if (item) {
            setValue("name", item.name)
            setValue("label", item.label)
            setValue("type", item.type)
        }
    }

    const deprecated = watch("deprecated") 

    const watchType = watch("type") || item?.type
    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-2 w-full'>

            <div className="flex flex-row items-center justify-end">
                <span className="flex-auto0 px-2">Популярные варианты</span>
                <ContextMenuButton 
                    menu={PREFIELDS_MENU}
                    onClick={prefieldsClickHandler}
                    />
            </div>

            <div className="px-2 flex flex-row gap-2 items-center" >
                <input type="checkbox" className="checkbox"
                    {...register("required")} disabled={deprecated}/>
                <span className={"select-none " + (deprecated ? "text-disabled" : "")}>{strings.msg_required}</span>
            </div>
            <div className="px-2 flex flex-row gap-2 items-center">
                <input type="checkbox" className="checkbox"
                    {...register("deprecated")} />
                <span className="select-none">Устаревшее поле</span>
            </div>

            <LabelWith text={strings.lab_template_field_type}
                error={errors.type}>
                <select className="combobox"
                    {...register("type", { required: true })} >
                    { TYPES.map( type => 
                        <option key={type.key || type.value || type.text} 
                            value={type.value || type.text}>{type.text}</option>
                    )}
                </select>
            </LabelWith>

            <LabelWith text={strings.lab_title} 
                error={errors.label}>
                <input className='textinput' 
                    placeholder={strings.lab_title}
                    {...register("label", { required: true })} />
            </LabelWith>
            
            <LabelWith text={strings.lab_template_field_name} 
                error={errors.name}>
                <input className='textinput' 
                    placeholder={strings.lab_template_field_name}
                    {...register("name", fieldNameValidation())} />
            </LabelWith>
            
            { watchType === TemplateFieldType.TEXT && 
            <LabelWith text={strings.lab_template_field_regex}
                error={errors.regex}>
                <input className="textinput"
                    placeholder={strings.lab_template_field_regex}
                    {...register("regex")}/>
            </LabelWith>
            }

            { watchType === TemplateFieldType.ENUM && 
            <LabelWith text={strings.lab_template_field_enum}>
                <textarea className="textinput"
                    placeholder={strings.lab_template_field_enum}
                    rows={5}
                    defaultValue={item?.enums?.join("\n")}
                    {...register("enums")}/>
            </LabelWith>
            }

            { (watchType === TemplateFieldType.ENUM || watchType === TemplateFieldType.TEXT
                 || watchType === TemplateFieldType.FILE
            ) && 
            <LabelWith text={strings.lab_template_field_hint} error={errors.hint}>
                <textarea className="textinput"
                    placeholder={strings.lab_template_field_hint}
                    {...register("hint")}/>
            </LabelWith>
            }

            <div className='flex flex-row justify-end gap-2 pt-4'>
                <button type='submit' className='greenbutton'>{strings.button_save}</button>
                {onCancel &&
                <button type='reset' className='textbutton' onClick={onCancel}>{strings.button_cancel}</button>
                }
            </div>
        </form>
    );
}