import { SubmitHandler } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { AppNameView } from '../comp/Navigation';
import { TabsView } from '../comp/TabsView';
import { useTitle } from '../hooks/useTitle';
import { useAppDispatch, useTypedSelector } from '../hooks/useTypedSelector';
import { loginAction, registerAction } from '../store/actions/auth';
import strings from '../strings';
import { JuryLoginTab } from './views/JuryLoginTab';
import { ILoginForm, LoginTab } from './views/LoginTab';
import { IRegisterForm, RegisterTab } from './views/RegisterTab';
import { Loading } from '../comp/Loading';
import { ErrorMessage } from '../comp/ErrorMessage';

export function LoginForm() {
    const [params] = useSearchParams()
    const juryData = params.get("jury")
    const { loading, error } = useTypedSelector(state => state.auth)

    const dispatch = useAppDispatch()
    useTitle(strings.nav_login)

    const onLoginSubmit: SubmitHandler<ILoginForm> = async (data) => {
        dispatch(loginAction(data.username, data.password))
    }
    const onRegisterSubmit: SubmitHandler<IRegisterForm> = async (data) => {
        dispatch(registerAction(data.fullname, data.username, data.password, data.registrationCode))
    }
    // if (loading)
    //     return ()
    return (
        <div className='relative'>
            <div className=' w-10/12 sm:w-96 mx-8 flex flex-col bg-primary3 rounded-lg drop-shadow-xl overflow-hidden my-10'
            >
                <div className=''
                    style={{
                        height: "150px",
                        overflow: 'hidden',
                    }}>
                    <div className=''
                        style={{
                            marginTop: "-50px",
                            marginLeft: "-50px",
                            marginRight: "-50px",
                            height: "250px",
                            backgroundSize: "120px",
                            backgroundRepeat: "repeat",
                            filter: "opacity(.1)",
                            transform: 'rotate(10deg)',
                            background: "url(/icon3.svg)"
                        }}>
                    </div>
                </div>
                <div className='absolute bg-black/5 w-full h-[150px]'></div>
                <AppNameView className='absolute-x-center mt-[100px]'
                    title={strings.appname} titleSmall={strings.appname2} />

                {error && <ErrorMessage className='mt-2' message={error} />}

                <TabsView className='mx-8 md:mx-16 mb-8 md:mb-16' initIndex={juryData ? 2 : 0}>
                    <LoginTab title={strings.nav_login}
                        onSubmit={onLoginSubmit} />

                    <RegisterTab title={strings.nav_register}
                        onSubmit={onRegisterSubmit} />
                    {juryData &&
                        <JuryLoginTab title={strings.nav_jury_login} />
                    }
                </TabsView>
                <span className='mx-8 md:mx-16 mb-8 md:mb-16 text-[#DDD] text-sm'>
                    {strings.msg_registration_limited}
                </span>
            </div>
            {loading &&
                <div className='absolute'>
                    <Loading />
                </div>
            }
        </div>
    );
}

