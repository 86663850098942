import { ITemplateField } from "../../model/models"
import { FieldError } from "react-hook-form";
import { LabelWith } from "../Label";

interface EditTextFieldProps {
    className?: string
    labelClassName?: string
    labelStyle?: React.CSSProperties
    field: ITemplateField
    value?: string
    onChange?: (e: any) => void
    onBlur?: () => void
    error?: FieldError
}

export function EditTextField({ className, labelClassName, labelStyle, value, error, onChange, onBlur, field }: EditTextFieldProps) {
    return (
        <LabelWith text={field.label} error={error}
            className={className}
            labelClassName={labelClassName}
            labelStyle={labelStyle}
        >

            <input className="textinput"
                placeholder={field.hint || field.label}
                value={value || ""}
                onChange={onChange}
                onBlur={onBlur}
                readOnly={onChange == undefined} />

        </LabelWith>
    )
}