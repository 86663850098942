import { useRef } from "react";

export interface DragItemHandler<T> {
    onStart: (item: T) => void
    onEnter: (onItem: T) => void
    onEnd: (item: T) => void
    onOver: (item: T) => void
}

export function useDragReorder<T>(
    items: T[],
    setItems: (nextItems: T[]) => void
) {
    const dragItem = useRef<T>();
    const dragOverItem = useRef<T>()

    function startDrag(item: T) {//index: number) {
        // console.log("dragStart: ", item)
        dragItem.current = item//index
    }
    function enterDrag(item: T) {//index: number) {
        // console.log("dragEnter: ", item)
        dragOverItem.current = item//index
    }
    function endDrag(item: T) {
        // console.log("DragEnd: ", item)

        if (dragItem.current !== undefined && dragOverItem.current !== undefined) {
            // console.log(`Drag ${dragItem.current} => ${dragOverItem.current}`)
            const dragIndex = items.indexOf(dragItem.current)
            const dragOverIndex = items.indexOf(dragOverItem.current)
            // console.log(`Drag ${dragIndex} => ${dragOverIndex}`)
            if (dragIndex >= 0 && dragOverIndex >= 0) {
                const copyListItems = [...items]
                const dragItemContent = copyListItems[dragIndex]//dragItem.current];
                copyListItems.splice(dragIndex, 1)
                copyListItems.splice(dragOverIndex, 0, dragItemContent);
                setItems(copyListItems)
            }
        }
        dragItem.current = undefined
        dragOverItem.current = undefined
    }
    function overDrag(item: T) {
        // console.log("DragOver: ", item)

        if (dragItem.current !== undefined && dragOverItem.current !== undefined) {
            // console.log(`Drag ${dragItem.current} => ${dragOverItem.current}`)
            const dragIndex = items.indexOf(dragItem.current)
            const dragOverIndex = items.indexOf(dragOverItem.current)
            // console.log(`Drag ${dragIndex} => ${dragOverIndex}`)
            if (dragIndex >= 0 && dragOverIndex >= 0) {
                const copyListItems = [...items]
                const dragItemContent = copyListItems[dragIndex]//dragItem.current];
                copyListItems.splice(dragIndex, 1)
                copyListItems.splice(dragOverIndex, 0, dragItemContent);
                setItems(copyListItems)
            }
        }
        // dragItem.current = undefined
        // dragOverItem.current = undefined
    }
    const handler = {
        onStart: startDrag,
        onEnd: endDrag,
        onEnter: enterDrag,
        onOver: overDrag
    } as DragItemHandler<T>
    return handler
}