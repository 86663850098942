import { useEffect } from 'react';
import { Route, Routes, Navigate, useSearchParams } from "react-router-dom";
import { AboutPage } from './pages/AboutPage';
import { Navigation } from './comp/Navigation';
import { LoginForm } from './pages/LoginForm';
import { useAppDispatch, useTypedSelector } from './hooks/useTypedSelector';
import { checkUserAction, checkJuryAction } from './store/actions/auth';
import { HomePage } from './pages/HomePage';
import { Footer } from './comp/Footer';
import DashboardPage from './pages/DashboardPage';
import ContestPage from './pages/ContestPage';
import { UserPage } from './pages/UserPage';
import { ContestEntityPage } from './pages/ContestEntityPage';
import { ParticipatePage } from './pages/ParticipatePage';
import MessagesPage from './pages/MessagesPage';
import { MessageReaderPage } from './pages/MessageReaderPage';
import ProtectedRoute from './comp/ProtectedRoute';
import paths from './paths';
import NewsPage from './pages/NewsPage';
import { DebugPage } from './pages/DebugPage';
import { ChatPage } from './pages/ChatPage';
import { PresentationPage } from './pages/PresentationPage';
import { PresentationPage2 } from './pages/PresentationPage2';
import { EditPresentationPage2 } from './pages/EditPresentationPage2';
import { EditDocumentPage } from './pages/EditDocumentPage';
import { ContestForJuryPage } from './pages/ContestForJuryPage';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { EntityForJuryPage } from './pages/EntityForJuryPage';
import { AdminPanelPage } from './pages/AdminPanelPage';
import { StaticPageEditorPage } from './pages/StaticPageEditorPage';
import { PublicStaticPage } from './pages/PublicStaticPage';
import { ContactPage } from './pages/ContactPage';
import strings from './strings';

function Main() {
    const [params] = useSearchParams()
    const { isAuth } = useTypedSelector(state => state.auth);
    return (
        <div className='appcontainer pt-big0 flex flex-col flex-auto items-center bg-primary text-onPrimary'>
            <Routes>
                <Route path={paths.home} element={
                    <HomePage />
                } />
                <Route path={paths.news} element={
                    <NewsPage />
                } />
                <Route path={paths.about} element={
                    <AboutPage />
                } />
                <Route path={paths.contact} element={
                    <ContactPage />
                } />
                <Route path={paths.debug} element={
                    <DebugPage />
                } />
                <Route path={paths.dashboard} element={
                    <ProtectedRoute>
                        <DashboardPage />
                    </ProtectedRoute>
                } />
                <Route path={paths.messages} element={
                    <ProtectedRoute>
                        <MessagesPage />
                    </ProtectedRoute>
                } />
                <Route path={paths.messages_chat(":chatId")} element={
                    <ProtectedRoute>
                        <ChatPage />
                    </ProtectedRoute>
                } />
                <Route path={paths.message_by_id(":id")} element={
                    <ProtectedRoute>
                        <MessageReaderPage />
                    </ProtectedRoute>
                } />
                <Route path={paths.user} element={
                    <ProtectedRoute>
                        <UserPage />
                    </ProtectedRoute>
                } />

                <Route path={paths.edit_table(":id")} element={
                    <ProtectedRoute>
                        <EditDocumentPage />
                    </ProtectedRoute>
                } />

                <Route path={paths.edit_presentation(":id")} element={
                    <ProtectedRoute>
                        <EditPresentationPage2 />
                    </ProtectedRoute>
                } />
                <Route path={paths.show_presentation(":id")} element={
                    <ProtectedRoute>
                        <PresentationPage2 />
                    </ProtectedRoute>
                } />
                <Route path={paths.presentation} element={
                    <ProtectedRoute>
                        <PresentationPage2 />
                    </ProtectedRoute>
                } />

                <Route path={paths.presentation_by_id(":id")} element={
                    <ProtectedRoute>
                        <PresentationPage />
                    </ProtectedRoute>
                } />

                <Route path={paths.contest_for_jury} element={
                    <ProtectedRoute forJury>
                        <ContestForJuryPage />
                    </ProtectedRoute>
                } />
                <Route path={paths.contest_entity_by_id_for_jury(":id")} element={
                    <ProtectedRoute forJury>
                        <EntityForJuryPage />
                    </ProtectedRoute>
                } />

                <Route path={paths.contest_by_id(":id")} element={
                    <ProtectedRoute>
                        <ContestPage />
                    </ProtectedRoute>
                } />
                <Route path={paths.contest_entity_by_id(":contestId", ":id")} element={
                    <ProtectedRoute>
                        <ContestEntityPage />
                    </ProtectedRoute>
                } />
                <Route path={paths.static_pages_by_id(":contestId", ":id")} element={
                    <ProtectedRoute>
                        <StaticPageEditorPage />
                    </ProtectedRoute>
                } />

                <Route path={paths.public_contest_by_id(":contestId")} element={
                    <ParticipatePage />
                } />
                <Route path={paths.public_static_pages(":id")} element={
                    <PublicStaticPage />
                } />
                <Route path={paths.login} element={
                    !isAuth ? <LoginForm /> : <Navigate to={params.get("redirect") || paths.home} />
                } />
                <Route path={paths.admin} element={
                    <ProtectedRoute forSiteAdmin>
                        <AdminPanelPage />
                    </ProtectedRoute>
                } />

                {/* <Route path={paths.xlsx_template(":contestId", ":id")} element={ <DocTemplatePage /> } /> */}
                <Route path='*' element={<Navigate to={paths.home} />} />
            </Routes>
        </div>
    )
}

export default function App() {
    const dispatch = useAppDispatch()

    const { user, jury } = useTypedSelector(state => state.auth)

    useEffect(() => {
        if (user) {
            dispatch(checkUserAction())
        } else if (jury) {
            dispatch(checkJuryAction())
        }
    }, []);

    return (
        <Routes>
            <Route path={paths.home} element={
                <HomePage />
            } />
            <Route path={paths.public_static_pages(":id")} element={
                <>
                    {/* <div className='appcontainer pt-big flex flex-col flex-auto items-center bg-primary text-onPrimary'> */}
                    <PublicStaticPage />
                    {/* </div> */}
                    <ToastContainer theme='dark'
                        hideProgressBar
                        closeButton={false}
                        position='bottom-center'

                        bodyClassName="text-onPrimary text-xs p-1"
                        bodyStyle={{ background: "#808EA5" }}
                        toastStyle={{ background: "#808EA5" }}
                    />
                </>
            } />
            <Route path='*' element={
                <>
                    <Navigation title={strings.appname} titleSmall={strings.appname2} />
                    <Main />
                    <Footer />
                    <ToastContainer theme='dark'
                        hideProgressBar
                        closeButton={false}
                        position='bottom-center'

                        bodyClassName="text-onPrimary text-xs p-1"
                        bodyStyle={{ background: "#808EA5" }}
                        toastStyle={{ background: "#808EA5" }}
                    />
                </>
            } />
        </Routes>
    );
}