import { useState } from "react"
import { Modal } from "../../comp/Modal"
import { TextField } from "../../comp/TextField"
import { useModal } from "../../hooks/useModal"
import { defaultReplayEntityTemplateVars, EntityState, IContest, IRepliesTemplates, IRepliesTemplatesVars, repliesTemplatesVars } from "../../model/models"
import DataService from "../../services/DataService"
import strings from "../../strings"
import { LabelWith } from "../../comp/Label"

type EditTemplate = { body: string, kind: EntityState, subj: boolean };

interface IContestTemplatesPageProps {
    contest: IContest
    setContest: (nextContest: IContest) => void
}

export function ContestTemplatesPage({ contest, setContest }: IContestTemplatesPageProps) {
    const editReplyModal = useModal<EditTemplate>();

    async function saveRepliesTemplates(next: IRepliesTemplates) {
        try {
            const data = { ...contest, repliesTemplates: next } as IContest
            const resp = await DataService.saveContest(contest.id, data)
            setContest(resp.data)
        } catch (e) {
            console.error(e)
            // const err = e as AxiosError
            // setError(err.message || "Can't delete contest!")
        }
    }
    function setTemplate(templ: string, state: EntityState, subj: boolean) {
        if (!subj && templ.length > 0 && state == EntityState.CORRECTION_REQUIRED && templ.indexOf(repliesTemplatesVars.message) < 0) {
            templ += ": " + repliesTemplatesVars.message;
        }
        switch (state) {
            case EntityState.APPROVED: {
                if (subj)
                    saveRepliesTemplates({ ...contest.repliesTemplates, approvedEntitySubj: templ })
                else
                    saveRepliesTemplates({ ...contest.repliesTemplates, approvedEntity: templ })
                break;
            }
            case EntityState.REJECTED: {
                if (subj)
                    saveRepliesTemplates({ ...contest.repliesTemplates, rejectedEntitySubj: templ })
                else
                    saveRepliesTemplates({ ...contest.repliesTemplates, rejectedEntity: templ })
                break;
            }
            case EntityState.CORRECTION_REQUIRED: {
                if (subj)
                    saveRepliesTemplates({ ...contest.repliesTemplates, correctionRequiredEntitySubj: templ })
                else
                    saveRepliesTemplates({ ...contest.repliesTemplates, correctionRequiredEntity: templ })
                break;
            }
        }
        editReplyModal.closeModal()
    }
    function changeTemplate(body: string, kind: EntityState, subj: boolean) {
        if (contest.isAdmin != true) {
            return;
        }
        editReplyModal.openModal({ body, kind, subj });
    }
    return (
        <div className="flex flex-col">
            <TextField className="flex-auto"
                label='Шаблон заголовка ответа "Заявка одобрена"'
                text={contest.repliesTemplates?.approvedEntitySubj}
                emptyPlaceholder={strings.msg_missing}
                buttonIcon={contest.isAdmin == true ? "edit" : undefined}
                buttonOnClick={() => changeTemplate(contest.repliesTemplates?.approvedEntitySubj || "", EntityState.APPROVED, true)} />
            <TextField className="flex-auto" multiline
                label='Шаблон тела ответа "Заявка одобрена"'
                text={contest.repliesTemplates?.approvedEntity}
                emptyPlaceholder={strings.msg_missing}
                buttonIcon={contest.isAdmin == true ? "edit" : undefined}
                buttonOnClick={() => changeTemplate(contest.repliesTemplates?.approvedEntity || "", EntityState.APPROVED, false)} />

            <TextField className="flex-auto mt-8"
                label='Шаблон заголовка ответа "Заявка отклонена"'
                text={contest.repliesTemplates?.rejectedEntitySubj}
                emptyPlaceholder={strings.msg_missing}
                buttonIcon={contest.isAdmin == true ? "edit" : undefined}
                buttonOnClick={() => changeTemplate(contest.repliesTemplates?.rejectedEntitySubj || "", EntityState.REJECTED, true)} />
            <TextField className="flex-auto" multiline
                label='Шаблон тела ответа "Заявка отклонена"'
                text={contest.repliesTemplates?.rejectedEntity}
                emptyPlaceholder={strings.msg_missing}
                buttonIcon={contest.isAdmin == true ? "edit" : undefined}
                buttonOnClick={() => changeTemplate(contest.repliesTemplates?.rejectedEntity || "", EntityState.REJECTED, false)} />

            <TextField className="flex-auto mt-8"
                label='Шаблон заголовка ответа "Требуется коррекция заявки"'
                text={contest.repliesTemplates?.correctionRequiredEntitySubj}
                emptyPlaceholder={strings.msg_missing}
                buttonIcon={contest.isAdmin == true ? "edit" : undefined}
                buttonOnClick={() => changeTemplate(contest.repliesTemplates?.correctionRequiredEntitySubj || "", EntityState.CORRECTION_REQUIRED, true)} />
            <TextField className="flex-auto" multiline
                label='Шаблон тела ответа "Требуется коррекция заявки"'
                text={contest.repliesTemplates?.correctionRequiredEntity}
                emptyPlaceholder={strings.msg_missing}
                buttonIcon={contest.isAdmin == true ? "edit" : undefined}
                buttonOnClick={() => changeTemplate(contest.repliesTemplates?.correctionRequiredEntity || "", EntityState.CORRECTION_REQUIRED, false)} />

            {editReplyModal.modal &&
                <Modal title="" onClose={editReplyModal.closeModal}>
                    <EditReplyTemplate
                        body={editReplyModal.modal.body}
                        kind={editReplyModal.modal.kind}
                        subj={editReplyModal.modal.subj}
                        vars={defaultReplayEntityTemplateVars}
                        onSubmit={setTemplate}
                        onClose={editReplyModal.closeModal} />
                </Modal>
            }
        </div>
    )
}

interface IEditReplyTemplateProps {
    kind: EntityState
    body: string
    subj: boolean
    vars: IRepliesTemplatesVars// { [name: string]: string | undefined }
    onSubmit: (next: string, kind: EntityState, subj: boolean) => void
    onClose: () => void
}

function EditReplyTemplate({ kind, body, subj, vars, onSubmit, onClose }: IEditReplyTemplateProps) {
    const [text, setText] = useState<string>(() => body);
    return (
        <div className="flex flex-col">
            <LabelWith text="Шаблон ответа по электронной почте">
                <textarea className="textfield"
                    placeholder="Шаблон ответа по умолчанию"
                    rows={5}
                    value={text} onChange={e => setText(e.target.value)}
                />
            </LabelWith>
            <div className="flex flex-row pt-4 w-full justify-end gap-x-2">
                <button className="textbutton" onClick={() => onSubmit(text, kind, subj)}>{strings.button_save}</button>
                <button className="textbutton" onClick={onClose}>{strings.button_close}</button>
            </div>
        </div>
    )
}