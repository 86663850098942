import { useState } from "react"
import { Label } from "../../../comp/Label"
import { IPadding } from "../../../model/common"

interface EditPaddingTabProps {
    padding: IPadding | undefined
    defaultValue?: number | undefined
    onChange?: (nextPadding: IPadding) => void
}

export function EditPaddingTab({ padding, defaultValue, onChange }: EditPaddingTabProps) {
    const [value, setValue] = useState<IPadding>(padding || {})
    function setPadding(nextPadding: IPadding) {
        setValue(nextPadding)
        onChange && onChange(nextPadding)
    }
    // console.log("defaultValue=", defaultValue);
    return (
        <div title="Padding"
            className="flex flex-row justify-center items-center p-4">
            <div className="flex flex-col">
                <Label text="Left" />
                <input type="number" className="textfield w-[70px] "
                    value={value.left || defaultValue}
                    onChange={e => setPadding({ ...value, left: parseInt(e.target.value) || defaultValue })}
                />
            </div>
            <div className="flex flex-col items-center">
                <Label text="Top" />
                <input type="number" className="textfield w-[70px] "
                    value={value.top || defaultValue}
                    onChange={e => setPadding({ ...value, top: parseInt(e.target.value) || defaultValue })}
                />
                <Label text="Bottom" />
                <input type="number" className="textfield w-[70px] "
                    value={value.bottom || defaultValue}
                    onChange={e => setPadding({ ...value, bottom: parseInt(e.target.value) || defaultValue })}
                />
            </div>
            <div className="flex flex-col items-end">
                <Label text="Right" className="justify-end" />
                <input type="number" className="textfield w-[70px] "
                    value={value.right || defaultValue}
                    onChange={e => setPadding({ ...value, right: parseInt(e.target.value) || defaultValue })}
                />
            </div>
        </div>
    )
}
