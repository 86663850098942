import { FieldError } from "react-hook-form"
import { ITemplateField, TemplateFieldType, dbid } from "../../model/models"
import strings from "../../strings"
import { LabelWith } from "../Label"
import { EmptyView } from "../Loading"
import { EditEnumField } from "./EditEnumField"
import { EditImageField } from "./EditImageField"
import { EditImagesField } from "./EditImagesField"
import { EditTextField } from "./EditTextField"
import { EditFileField } from "./EditFileField"
import React from "react"

// export class UploadFilesController {
//     fields: UploadFilesControllerItem[]

//     constructor() {
//         this.fields = [];
//     }

//     get(field: string) {
//         var item = this.fields.find(e => e.name == field);
//         if (!item) {
//             item = new UploadFilesControllerItem(field);
//             this.fields.push(item);
//         }
//         return item.uploads;
//     }
// }

// class UploadFilesControllerItem {
//     name: string;
//     uploads: IUploadFile[]
//     constructor(name: string) {
//         this.name = name;
//         this.uploads = new Array<IUploadFile>();
//     }
// }

interface EditFieldProps {
    className?: string
    labelClassName?: string
    labelStyle?: React.CSSProperties
    buttonStyle?: React.CSSProperties
    contestId: dbid
    entityId?: dbid
    field: ITemplateField
    value?: string
    error?: FieldError
    onChange?: (e: any) => void
    onBlur?: () => void
}

export function EditField({ className, labelClassName, labelStyle, buttonStyle, contestId, entityId, value, error, onChange, onBlur, field }: EditFieldProps) {
    switch (field.type) {
        case TemplateFieldType.TEXT:
            return (
                <EditTextField
                    className={className} labelClassName={labelClassName}
                    labelStyle={labelStyle}
                    value={value} error={error}
                    onChange={onChange} onBlur={onBlur}
                    field={field}
                />
            )
        case TemplateFieldType.ENUM:
            return (
                <EditEnumField
                    className={className} labelClassName={labelClassName}
                    labelStyle={labelStyle}
                    value={value} error={error}
                    onChange={onChange} onBlur={onBlur}
                    field={field}
                />
            )
        case TemplateFieldType.BOOL:
            return (
                <LabelWith text={field.label} className={className} labelClassName={labelClassName}
                    labelStyle={labelStyle}>
                    <EmptyView text="Not implemented yet!" />
                </LabelWith>
            )
        case TemplateFieldType.IMAGE:
            return (
                <EditImageField
                    className={className} labelClassName={labelClassName}
                    labelStyle={labelStyle}
                    buttonStyle={buttonStyle}
                    contestId={contestId} entityId={entityId}
                    value={value} error={error}
                    onChange={onChange} onBlur={onBlur}
                    label={field.label}
                    required={field.required} />
            )
        case TemplateFieldType.IMAGES:
            return (
                <EditImagesField
                    className={className} labelClassName={labelClassName}
                    labelStyle={labelStyle}
                    buttonStyle={buttonStyle}
                    contestId={contestId} entityId={entityId}
                    // uploads={uploads?.get(field.name)}
                    images={value?.split("\n") || []}
                    error={error}
                    onChange={onChange ? images => onChange(images.join("\n")) : undefined}
                    onBlur={onBlur}
                    field={field} />
            )
        case TemplateFieldType.FILE:
            return (
                <EditFileField
                    className={className} labelClassName={labelClassName}
                    labelStyle={labelStyle}
                    buttonStyle={buttonStyle}
                    contestId={contestId} entityId={entityId}
                    value={value} error={error}
                    onChange={onChange} onBlur={onBlur}
                    label={field.label}
                    hint={field.hint}
                    required={field.required} />
            );
        case TemplateFieldType.FILES:
            return (
                <LabelWith text={field.label}
                    className={className} labelClassName={labelClassName}
                    labelStyle={labelStyle}>
                    <EmptyView text="Not implemented yet!" />
                </LabelWith>
            )

        case TemplateFieldType.VIDEO:
            return (
                <LabelWith text={field.label}
                    className={className} labelClassName={labelClassName}
                    labelStyle={labelStyle}>
                    <EmptyView text="Not implemented yet!" />
                </LabelWith>
            )
        default:
            return (
                <LabelWith text={field.label}
                    className={className} labelClassName={labelClassName}
                    labelStyle={labelStyle}>
                    <EmptyView text={`${strings.msg_unknown_field} ${field.type} name=${field.name}`} />
                </LabelWith>
            )
    }
}