import { afterToken, beforToken } from "./string";

export function parserClassName(className: String | undefined, prev?: React.CSSProperties): React.CSSProperties | undefined {
    if (!className) {
        return undefined;
    }
    const res = prev || {} as React.CSSProperties;
    const obj = (res as any);
    className.split(';').forEach(s => {
        const name = beforToken(s, ':');
        const value = afterToken(s, ":");
        if (name && value) {
            obj[name.trim()] = value.trim();
        }
    });
    // console.log(className, res, obj);
    return res;
}

// var a = parseStyleCss("background: #474972; fontSize: 22px; fontWeight: bold; height: 60px; width: 100%; @media (width >=768px) { height: 10px; }")
// console.log("parseStyleCss", a);

// Example: @media (width >=768px) { }
export function parseStyleCss(s: string | undefined): React.CSSProperties | undefined {
    if (!s) {
        return undefined;
    }
    var css = {} as React.CSSProperties
    const obj = (css as any);
    var s0 = s.trim()
    const blocks = []
    while (s0.length != 0) {
        if (s0.startsWith("@")) {
            var blockDecl = beforToken(s0, '{', s0)!!.trim()
            s0 = afterToken(s0, '{', "")!!
            var block = beforToken(s0, '}', s0)!!.trim()
            if (checkBlock(blockDecl)) {
                blocks.push(block)
            }
            s0 = afterToken(s0, '}', "")!!.trim()
        } else {
            var s1 = beforToken(s0, ';', s0)!!.trim()
            setCssItem(s1, obj)
            s0 = afterToken(s0, ';', "")!!.trim()
        }
    }
    blocks.forEach(block => {
        parserCss(block, css)
    })
    return css;
}

function checkBlock(block: string): boolean {
    if (block.startsWith("@media")) {
        var s0 = afterToken(block, "(", "")!!
        var cond = beforToken(s0, ")", "")!!.trim();//.split(blockDeclSplit)
        if (cond.length == 0) {
            return true
        }
        try {
            let cond2 = cond
                .replace("width", global.window.innerWidth.toString())
                .replace("height", global.window.innerHeight.toString())
                .replace("px", "")
            // console.log(cond, cond2);
            let b = eval(cond2)
            if (typeof b == "boolean") {
                return b;
            }
        } catch (e) {
            return false;
        }
        return false;
    }

    return false;
}

var cssItemSplit = new RegExp("\s*;\s*");

function parserCss(s: string, css: React.CSSProperties): React.CSSProperties {
    if (s.length > 0) {
        const obj = (css as any);
        s.split(cssItemSplit).forEach(s => {
            setCssItem(s, obj);
        });
    }
    return css;
}

function setCssItem(s: string, obj: any) {
    if (s.length == 0) {
        return
    }
    // console.log("setCssItem", s);
    const name = beforToken(s, ':');
    const value = afterToken(s, ":");
    if (name && value) {
        obj[name.trim()] = value.trim();
    }
}
export { }