import { ErrorMessage } from "../comp/ErrorMessage";
import { Icon, IconButton } from "../comp/Icon";
import { ImageEx } from "../comp/ImageFB";
import { LabelWith } from "../comp/Label";
import { ListItems } from "../comp/ListItems";
import { EmptyView, Loading } from "../comp/Loading";
import ContestEntityRow from "../comp/rows/ContestEntityRow";
import { useTocData } from "../hooks/useTocData";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { IContestEntity, PhotoSize, dbid } from "../model/models";
import { ITocItem, TocSection } from "../model/query";
import strings from "../strings";

interface TextBlockProps {
    text: string
    align?: "start" | "center" | "end"
    color?: "none" | "primary" | "primary2" | "primary3" | "secondary"
}

function TextBlock({ text, align, color }: TextBlockProps) {
    let valing = align || "end"
    let shadow = color !== "none" ? "drop-shadow" : ""
    let bgColor = color || "primary"
    let onColor = color === "secondary" ? "text-onSecondary" : "text-onPrimary"
    return (
        <div className={`bg-${bgColor} ${onColor} ${shadow} flex flex-col justify-${valing} items-start text-2xl font-thin0 italic p-4 rounded-lg w-[180px] min-h-[150px] overflow-hidden`}>
            {text.split("\n").map((s, index) => <div key={index + s}>{s}</div>)}
        </div>
    )
}

export function ContestForJuryPage() {
    const { jury } = useTypedSelector(state => state.auth)
    const contestId = jury?.juryContestId || ""
    const { contest, toc, loading, error, loadSection } = useTocData(contestId, { onlySection: 0 })
    return (
        <div className="maincontainer w-full flex flex-col">
            {loading
                ? <Loading />
                : (toc && contest)
                    ? <>
                        <h1 className="text-4xl text-right pb-8">{contest.title}</h1>
                        <div className="flex flex-row justify-between">
                            {contest.logo &&
                                <ImageEx src={contest.logo} size={PhotoSize.large} className="rounded max-w-[500px]" />
                            }
                            <div className="flex flex-col justify-between ">
                                <TextBlock text={contest.nominations + "\n" + strings.nominationsFor(contest.nominations)} color="none" align="start" />
                                <TextBlock text={contest.entities + "\n" + strings.entitiesFor(contest.entities)} color="none" align="start" />
                            </div>
                        </div>
                        {jury && jury.query && (jury.query.nominationIds.length > 0 || jury.query.entityQueries.length > 0) &&
                            <div className="p-4 self-end text-disabled">Вы можете видеть ограниченное кол-во участников</div>
                        }
                        <LabelWith text={strings.lab_contest_entities} className="pt-4 pb-big">
                            <div className="flex flex-col">
                                {toc.sections.length === 0
                                    ? <EmptyView text={strings.msg_empty_entities} />
                                    : toc.sections.map(sect =>
                                        <NominationSectionView key={sect.nomination.id}
                                            contestId={contestId}
                                            nominationId={sect.nomination.id}
                                            sect={sect}
                                            text={sect.nomination.title}
                                            items={sect.items}
                                            onLoad={() => loadSection(sect, toc)} />
                                    )}
                            </div>
                        </LabelWith>
                    </>
                    : <ErrorMessage message={error || strings.err_load_contest} />
            }
        </div>
    )
}

interface NominationSectionViewProps {
    contestId: dbid
    // sect: ITocSection
    nominationId: dbid
    text: string
    items: ITocItem[]
    sect: TocSection
    onLoad: () => void
}

export function NominationSectionView({ contestId, nominationId, text, items, sect, onLoad }: NominationSectionViewProps) {
    return (
        <div key={nominationId} className="flex flex-col">
            <div className={"px-2 pt-4 text-lg flex flex-row " + (!sect.loaded ? "cursor-default hover:bg-white/5" : "")} onClick={!sect.loaded ? onLoad : undefined}>
                <div className="flex-auto">{text}</div>
                {!sect.loaded &&
                    <Icon name="arrow_drop_down" />
                }
            </div>
            {sect.loaded &&
                <ListItems className="pl-4"
                    items={items}
                    emptyText={strings.msg_empty_entities}
                    render={item =>
                        <ContestEntityRow key={item.id}
                            contestId={contestId}
                            item={item.entity}
                            forJury />
                    }
                />
            }
        </div>
    )
}