import { IDataQuery } from "./query";

export type dbid = string;

export interface IError {
  message?: string;
}

export interface IUser {
  id: string
  email?: string
  username: string
  fullname: string
  messages?: number
  avatar?: string | null
  juryContestId?: string
  isJury?: boolean
  isSiteAdmin?: boolean
  isLimited?: boolean
  presentationId?: dbid
  stage?: dbid
  query?: IDataQuery
}

export interface ISubmitter {
  email: string;
}

export interface IContestUser {
  id: dbid;
  contestId: dbid;
  name: string;
  username?: string;
  avatar?: string;
  description?: string;
  isAdmin: boolean;
  isJury: boolean;
  stage?: dbid;
  stages?: dbid[];
  presentationId?: dbid;
  query?: IDataQuery;
}

export function instanceOfUser(object: any): object is IUser {
  return "fullname" in object && "username" in object;
}

export enum TemplateFieldType {
  TEXT = "TEXT",
  ENUM = "ENUM",
  BOOL = "BOOL",
  IMAGE = "IMAGE",
  IMAGES = "IMAGES",
  FILE = "FILE",
  FILES = "FILES",
  VIDEO = "VIDEO",
  OBJECT = "OBJECT",
  ARRAY = "ARRAY",
}

export interface ITemplateField {
  index?: number
  required: boolean
  deprecated?: boolean
  type: TemplateFieldType
  name: string
  label: string
  regex?: string
  enums?: string[]
  hint?: string
}

export interface ITemplate {
  fields: ITemplateField[];
}

export enum VoteKind {
  SINGLE = "SINGLE",
  MANY = "MANY",
  PLACES = "PLACES",
  POINTS = "POINTS"
}

export interface IVoteRules {
  voteKind: VoteKind;
  numbers?: number;
}

export interface IStage {
  id: dbid;
  num: number;
  title: string;
  description: string;
  voting: IVoteRules;
}

export interface IContestExample {
  id: string;
  title: string;
  description?: string;
}

// export interface IContestSettings {
//     listenEmails?: string[]
// }

export interface IContest {
  id: dbid;
  title: string;
  description?: string;
  logo?: string;
  email?: string;
  replyToEmail?: string;
  repliesTemplates?: IRepliesTemplates;
  maxNominations?: number;
  isPublished: boolean;
  isOpenSubmit?: boolean;
  isAdmin?: boolean;
  isParticipating?: boolean;
  isDeleted?: boolean;
  stages: IStage[];
  currentStage?: dbid;
  template?: ITemplate;
  nominations: number;
  entities: number;
  attachments?: IAttachmentFile[]
}

export const MAX_NOMINATION_COUNT = 2

export interface IRepliesTemplates {
  approvedEntitySubj?: string
  approvedEntity?: string
  rejectedEntitySubj?: string
  rejectedEntity?: string
  correctionRequiredEntitySubj?: string
  correctionRequiredEntity?: string
  deletedEntitySubj?: string
  deletedEntity?: string
}

export interface IRepliesTemplatesVars {
  contestTitle: string
  entityTitle: string
  entityUri: string
  message: string
}

export const repliesTemplatesVars = {
  contestTitle: "\${CONTEST_TITLE}",
  entityTitle: "\${ENTITY_TITLE}",
  entityUri: "\${ENTITY_URI}",
  message: "\${MESSAGE}"
} as IRepliesTemplatesVars

export const defaultReplayEntityTemplateVars = {
  contestTitle: "Название конкурса",
  entityTitle: "Название объекта",
  entityUri: "Ссылка на страницу объекта",
  message: "Сообщение от администратора",
} as IRepliesTemplatesVars

export const defaultRepliesTemplates = {
  approvedEntitySubj: "Ваша заявка одобрена: \"${ENTITY_TITLE}\"",
  approvedEntity: "Заявка на участие в конкурсе \"${CONTEST_TITLE}\" одобрена.\n" +
//                    "<b>Вы больше не можете редактировать или удалить данную заявку!<b>\n" +
          "${MESSAGE}\n"
//                    "Перейти по ссылке:\n<a href=\"${ENTITY_URI}\">Страница заявки \"${ENTITY_TITLE}\"</a>"
  ,
  rejectedEntitySubj: "Ваша заявка окончательно отклонена: ${ENTITY_TITLE}",
  rejectedEntity: "К сожалению Ваша заявка на участие в конкурсе \"${CONTEST_TITLE}\" окончательно отклонена.\n" +
//                    "<b>Вы больше не можете редактировать или подать данную заявку повторно!<b>" +
          "${MESSAGE}\n"
//                    "Перейти по ссылке:\n<a href=\"${MessageService.MESSAGE_URI}\">Страница заявки \"VAR_ENTITY_TITLE\"</a>"
  ,
  correctionRequiredEntitySubj: "Требуется коррекция заявки: ${ENTITY_TITLE}",
  correctionRequiredEntity: "В анкету на участие в конкурсе \"${CONTEST_TITLE}\" требуется внести изменения:\n" +
      "${MESSAGE}\n"
//                    "Перейти по ссылке:\n<a href=\"${ENTITY_URI}\">Страница заявки \"${ENTITY_TITLE}\"</a>"
  ,
  deletedEntitySubj: "Ваша заявка \"${ENTITY_TITLE}\" отклонена и удалена",
  deletedEntity: "Ваша анкета на участие в конкурсе \"${CONTEST_TITLE}\" удалена!\n" +
          "${MESSAGE}\n"
} as IRepliesTemplates

export interface INomination {
  id: dbid;
  title: string;
  description: string;
  index: number;
  active: number;
}

export interface IStaticPageHeader {
  id: dbid;
  contestId: dbid;
  title: string;
  description: string;
  isPublic: boolean;
  uri: string;
}

export interface IStaticForm {
  title: string;
  titleLabel?: string;
  titleHint?: string;
  fields: ITemplateField[];
  nominations?: INomination[];
  maxNominations?: number
}

export interface IStaticPage {
  id: dbid;
  contestId: dbid;
  title: string;
  description: string;
  registrationStart?: number
  registrationFinish?: number
  gratitude?: string
  uri?: string;
  isPublic: boolean;
  forms?: IStaticForm[];
  // fields: ITemplateField[];

  dialogStyle?: string;
  formStyle?: string;
  formLogo?: string;
  formTitle?: string;

  submitStyle?: string;
  submitText?: string;
  cancelStyle?: string
  cancelText?: string;

  fieldsClassName?: string;
  fieldsLabelStyle?: string;
}

// export interface IStaticPageLayer {
//   childs: IStaticPageDiv[];
// }
// export interface IStaticPageDiv {
//   className?: string;
//   image?: string;
//   text?: string;
//   // left?: number
//   // top?: number
//   width?: number;
//   height?: number;
//   childs?: IStaticPageDiv[];
// }

export interface IVoteSubmit {
  [index: string]: number | undefined;
}

export interface IVoteItemsResult {
  [index: string]: IVoteSubmit | undefined;
}

export interface IVoteResult {
  id: dbid;
  name: string;
  scopeId: string;
  items: IVoteItemsResult;
}

export interface IUserVoteResult {
  id: dbid;
  name: string;
  start: number;
  end: number;
  userId?: dbid;
  juryId?: dbid;
}

export enum EntityState {
  NOT_SUBMITTED = "NOT_SUBMITTED", // "Не подана заявка"
  VERIFICATION = "VERIFICATION", // "Заявка на проверке"
  APPROVED = "APPROVED", // "Заявка одобрена"
  REJECTED = "REJECTED", // "Заявка отклонена"
  CORRECTION_REQUIRED = "CORRECTION_REQUIRED", // "Требуется коррекция заявки"
}

export interface IFields {
  [index: string]: string | undefined;
}

export interface IAttachmentFile {
  filename: string;
  url: string;
  contentType: string;
}

export interface ILinkToNomination {
  stages?: string[];
}

export interface INominations {
  [index: dbid]: ILinkToNomination | undefined;
}

export interface IContestEntity {
  id: dbid;
  nominationIds?: dbid[];
  nominations?: INominations;
  title: string;
  description: string;
  created?: number;
  edited?: number;
  state: EntityState;
  fields: IFields;
  texts?: string[];
  attachments?: IAttachmentFile[];
  isAdmin: boolean;
  canEdit: boolean;
  canDelete: boolean;
}

export function instanceOfContestEntity(object: any): object is IContestEntity {
  return (
    "id" in object &&
    "title" in object &&
    "fields" in object &&
    "state" in object
  );
}

export interface IUserMessage {
  id: dbid;
  name: string;
}

export interface IMessage {
  id: dbid;
  chatId?: string;
  replyId?: dbid;
  from: IUserMessage;
  title: string;
  body?: string;
  date: number;
  isRead: boolean;
}

export interface ISendMessage {
  chatId?: string;
  replyId?: dbid;
  to?: dbid;
  email?: dbid;
  title: string;
  body: string;
}

export interface IPhoto {
  orig: string;
  small?: string;
  medium?: string;
  large?: string;
}

export interface IJob {
  id: dbid;
  name: string;
  start: number;
  end?: number;
  error?: string;
  canceled: boolean;
}

export interface IDocument {
  id: dbid;
  name: string;
  date: number;
  uri: string;
}

export enum PhotoSize {
  orig = "orig",
  small = "small",
  medium = "medium",
  big = "big",
  large = "large",
}

export interface INewsHeader {
  title: string
  description: string
  image: string
  date: string
  uri: string
}